<div id="mobileNav" *ngIf="params" [class]="{ active: showNav, overflowY:!displayDeletePrompt }">
	<div class="container930 px-3 min-h-100 d-flex flex-column">
		<div class="row" style="justify-content: right; margin-right: 10px">
			<div
				class="svgIcons close_rounded cursorPointer"
				(click)="close()"
			></div>
		</div>
		<div
			class="row"
			style="justify-content: center; justify-content: middle"
		>
			<a routerLink="/home" (click)="close()">
				<img class="logo" [src]="params.group_logo" />
			</a>
		</div>

		<div class="navList">
			<div
				class="d-flex align-items-center mb-2 py-auto"
				*ngIf="params.main_menu_options['show_our_menu']"
			>
				<span class="svgIcons navOurMenu mr-3"></span>
				<span class="">
					<h2 [routerLink]="'/order-type'" mat-ripple class="color1">
						{{ "nav.our_menu" | translate }}
					</h2>
				</span>
			</div>
			<div
				class="d-flex align-items-center mb-2 py-auto"
				*ngIf="params.main_menu_options['show_find_location']"
			>
				<span class="svgIcons navLocations mr-3"></span>
				<span class="">
					<h2
						[routerLink]="'/locations/all'"
						mat-ripple
						class="color1"
					>
						{{ "nav.locations" | translate }}
					</h2>
				</span>
			</div>
			<div
				class="d-flex align-items-center mb-2 py-auto"
				*ngIf="params.main_menu_options['show_rewards']"
			>
				<span class="svgIcons navRewards mr-3"></span>
				<span class="">
					<h2 [routerLink]="'/rewards'" mat-ripple class="color1">
						{{ "nav.rewards" | translate }}
					</h2>
				</span>
			</div>
			<div
				class="d-flex align-items-center mb-2 py-auto"
				*ngIf="params.main_menu_options['show_profile_settings']"
			>
				<span class="svgIcons navProfile mr-3"></span>
				<span class="">
					<h2 [routerLink]="'/profile'" mat-ripple class="color1">
						{{ "nav.profile" | translate }}
					</h2>
				</span>
			</div>
			<div
				class="d-flex align-items-center mb-2 py-auto"
				*ngIf="params.main_menu_options['show_recent_orders']"
			>
				<span class="svgIcons navOrders mr-3"></span>
				<span class="">
					<h2 [routerLink]="'/orders'" mat-ripple class="color1">
						{{ "nav.orders" | translate }}
					</h2>
				</span>
			</div>
			<div
				class="d-flex align-items-center mb-2 py-auto"
				*ngIf="params.main_menu_options['show_egift']"
			>
				<span class="svgIcons navEgift mr-3"></span>
				<span class="">
					<h2 [routerLink]="'/gift-cards'" mat-ripple class="color1">
						{{ "nav.buy_gift_card" | translate }}
					</h2>
				</span>
			</div>
			<div
				class="d-flex align-items-center mb-2 py-auto"
				*ngIf="params.main_menu_options['show_ewallet']"
			>
				<span class="svgIcons navEwallet mr-3"></span>
				<span class="">
					<h2 [routerLink]="'/buy-ewallet'" mat-ripple class="color1">
						{{ "nav.buy_ewallet" | translate }}
					</h2>
				</span>
			</div>
			<div
				class="d-flex align-items-center mb-2 py-auto"
				*ngIf="params.main_menu_options['show_refer_a_friend']"
			>
				<span class="svgIcons navReferFriend mr-3"></span>
				<span class="">
					<h2
						[routerLink]="'/refer-a-friend'"
						mat-ripple
						class="color1"
					>
						{{ "nav.refer_a_friend" | translate }}
					</h2>
				</span>
			</div>
		</div>

		<div class="mt-auto" *ngIf="!store.auth.isLoggedIn()">
			<div class="row pt-4">
				<div class="col-6">
					<a
						mat-raised-button
						routerLink="/signup"
						(click)="close()"
						>{{ "account.join_now" | translate }}</a
					>
				</div>
				<div class="col-6">
					<a
						mat-raised-button
						class="inverted"
						routerLink="/login"
						(click)="close()"
						>{{ "account.login" | translate }}</a
					>
				</div>
			</div>
		</div>

		<div class="mt-auto" *ngIf="store.auth.isLoggedIn()">
			<div class="row pt-4">
				<div class="col-12">
					<a mat-raised-button class="inverted" (click)="logout()">{{
						"account.logout" | translate
					}}</a>
				</div>
			</div>
			<div class="row pt-2">
				<div
					style="font-style: italic; justify-content: center"
					class="captText col-12 h2 color4 d-flex align-items-center py-2"
					*ngIf="store.auth.isLoggedIn()"
					(click)="deleteAccount()"
					mat-ripple
				>
					<h5 class="color4">{{"account.delete_account_link" | translate}}</h5>
				</div>
			</div>

		</div>

		<div
			class="row captText color8 text-center justify-content-center pt-4"
		>
			<div
				class="col-4 my-1"
				*ngIf="params.link_variables.privacy_policy_url"
			>
				<a
					(click)="
						openExternal(params.link_variables.privacy_policy_url)
					"
					>{{ "nav.privacy" | translate }}</a
				>
			</div>
			<div class="col-4 my-1" *ngIf="params.link_variables.use_terms_url">
				<a
					(click)="openExternal(params.link_variables.use_terms_url)"
					>{{ "nav.terms" | translate }}</a
				>
			</div>
			<div class="col-4 my-1" *ngIf="params.link_variables.custom_url">
				<a (click)="openExternal(params.link_variables.custom_url)">{{
					"nav.customer" | translate
				}}</a>
			</div>
			<div
				class="col-4 my-1 color1"
				*ngIf="params.link_variables.reservations_url"
			>
				<a
					(click)="
						openExternal(params.link_variables.reservations_url)
					"
					>{{ "nav.reservations" | translate }}</a
				>
			</div>
			<div class="col-12 h3 color4 text-center pt-3">
				<a
					(click)="openExternal(params.link_variables.facebook_url)"
					*ngIf="params.link_variables.facebook_url"
				>
					<i class="fab fa-facebook-f mx-2"></i>
				</a>
				<a
					(click)="openExternal(params.link_variables.instagram_url)"
					*ngIf="params.link_variables.instagram_url"
				>
					<i class="fab fa-instagram mx-2"></i>
				</a>
				<a
					(click)="openExternal(params.link_variables.twitter_url)"
					*ngIf="params.link_variables.twitter_url"
				>
					<i class="fab fa-twitter mx-2"></i>
				</a>
			</div>
			<div class="mt-3 color4">
				<p>v{{ versionNo }}</p>
			</div>
		</div>
	</div>
	<div class="row pt-2" id="deleteAccPrompt" *ngIf="displayDeletePrompt">
		<div
			style="font-style: italic; justify-content: center"
			class="col-12 h2 d-flex align-items-center py-2"
		>
			<app-delete-account-prompt></app-delete-account-prompt>
		</div>
	</div>
</div>
