<div class="ammountBox">
	<h3 class="my-3">{{ "gc.select_amount" | translate }}</h3>
	<mat-radio-group
		[(ngModel)]="gcard.show_amount"
		ngControl="gcard.show_amount"
		class="ammounts"
	>
		<mat-radio-button
			*ngFor="let v of amounts"
			[value]="v"
			(change)="getAmount($event)"
			>{{ gcard.currency?.symbol }}{{ v }}</mat-radio-button
		>
		<mat-radio-button value="custom" class="cust">{{
			"gc.custom_amount" | translate
		}}</mat-radio-button>
		<mat-form-field
			class="mt-3"
			*ngIf="gcard.show_amount == 'custom'"
			appearance="outline"
		>
			<input
				matInput
				type="number"
				inputmode="decimal"
				min="0.00"
				step="0.50"
				[value]="custom_amount"
				(focus)="onFocus($event)"
				(input)="changeCustomAmount($event)"
				(blur)="customTipDeselect($event)"
				[placeholder]="'gc.custom_amount' | translate"
				#custom_amount
			/>
		</mat-form-field>
	</mat-radio-group>
</div>
