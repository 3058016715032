import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
	enableProdMode();
}

declare var window;

const initApp = () => {
	platformBrowserDynamic().bootstrapModule(AppModule)
		.catch(err => console.error(err));
}
//waiting till device is ready
if (window.cordova) {
	document.addEventListener('deviceready', initApp, false);
} else {
	initApp();
}
