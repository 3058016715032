<div class="container mb-4">
	<h3 class="mx-2">{{"home.points" | translate}}</h3>
	<!-- <div class="svgIcons loayltyProfile mr-1"></div> -->
	<div class="position-relative">
		<app-dummy-placeholder *ngIf="dummy"></app-dummy-placeholder>
		<div class="" [class.dummyContent]="dummy">
			<div class="border rounded-lg p-2" routerLink="/rewards" mat-ripple>
				<h5 class="text-uppercase lineHeight1 d-flex justify-content-between align-items-center mb-3">
					<div [style]="{color: data.status_color}" class="text-center flex-grow-1">
						<h5>
							{{"rewards.your_current_status" | translate}} <br>
						</h5>
						<h3>
							{{data.status_level}}
						</h3>
					</div>
				</h5>
				<div class="text-center"> 
					<h4>
						{{data.total_points}} {{"rewards.points" | translate}}
					</h4>
					<!-- <div class="{color: data.status_color}">
						<app-level-progress [percentage]="data.percentage"></app-level-progress>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</div>
