import {Component, HostListener, OnInit} from '@angular/core';
import {Store} from "./store/store.service";
import {PushNotificationsService} from "src/app/services/push-notifications.service"

declare var window

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

	public showBottomBar = this.store.settings.getShowBottomBar();
	public backButton = false;

	constructor(private store: Store, private pushNotificationService: PushNotificationsService ) {}

	@HostListener('window:popstate', ['$event'])
	onPopState(event) {
		this.backButton = true;
		this.store.settings.commit("setBackButtonEvent", true);
		setTimeout(()=>{
			this.store.settings.commit("setBackButtonEvent", false);
		})
	}

	ngOnInit(): void {
		if(window.PushNotification && this.store.auth.isLoggedIn()) {		
			this.pushNotificationService.initNotifications();
		}
		this.store.settings.getStream$().subscribe(data=>{
			if (data.name === "setShowBottomBar"){
				setTimeout(()=>{
					this.showBottomBar = this.store.settings.getShowBottomBar()
				})
			}
		})
	}
}
