import {
	ChangeDetectorRef,
	Directive,
	ElementRef,
	forwardRef,
	HostListener,
	Input,
	OnChanges
} from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator,
	ValidatorFn
} from '@angular/forms';
import Inputmask from 'inputmask';

const MASK_CONTROL_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => UiMaskDirective),
	multi: true
};

const MASK_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => UiMaskDirective),
	multi: true
};

@Directive({
	selector: '[uiMask]',
	providers: [MASK_CONTROL_ACCESSOR, MASK_VALIDATORS]
})
export class UiMaskDirective
	implements ControlValueAccessor, OnChanges, Validator {
	@Input() uiMask: string;
	@Input() uiMaskOptions: any = {};
	@Input() uiMaskInModel: boolean;
	@Input() uiMaskValidation: boolean;

	private lastUnmaskedValue: string;
	private mask: any;
	private onTouch: () => {};
	private onModelChange: (value: any) => {};
	private onModelChangePromiseResolve;
	private onModelChangePromise = new Promise(
		resolve => (this.onModelChangePromiseResolve = resolve)
	);

	constructor(private el: ElementRef<any>, private cdr: ChangeDetectorRef) {}

	@HostListener('blur', ['$event'])
	onBlur() {
		this.onTouch();
		this.checkUpdateModel('blur');
	}
	@HostListener('input', ['$event'])
	onInput() {
		this.checkUpdateModel('input');
	}
	@HostListener('cut', ['$event'])
	onCut() {
		setTimeout(() => this.checkUpdateModel('cut'));
	}
	@HostListener('paste', ['$event'])
	onPaste() {
		setTimeout(() => this.checkUpdateModel('paste'));
	}

	ngOnChanges() {
		const ajsMask = this.fixAjsUiMaskOptionalChar(this.uiMask);
		this.mask = new Inputmask(ajsMask, {
			...this.uiMaskOptions,
			clearMaskOnLostFocus: false,
			greedy: true
		});
		this.mask.mask(this.el.nativeElement);
		this.checkUpdateModel('ngOnChanges');
		// NOTE: required to prevent expression changed error if the mask dynamically changes
		this.cdr.detectChanges();
		setTimeout(()=>{
			const ajsMask = this.fixAjsUiMaskOptionalChar(this.uiMask);
			this.mask = new Inputmask(ajsMask, {
				...this.uiMaskOptions,
				clearMaskOnLostFocus: false,
				greedy: true
			});
			this.mask.mask(this.el.nativeElement);
			this.checkUpdateModel('ngOnChanges');
			// NOTE: required to prevent expression changed error if the mask dynamically changes
			this.cdr.detectChanges();
		})

	}

	registerOnChange(fn) {
		this.onModelChange = fn;
		this.onModelChangePromiseResolve();
	}
	registerOnTouched(fn) {
		this.onTouch = fn;
	}
	writeValue(value) {
		this.el.nativeElement.inputmask?.setValue(value || '');
		this.checkUpdateModel('writeValue');
	}

	checkUpdateModel(type: string) {
		if (this.onModelChange) {
			this.updateModel();
		} else {
			// NOTE: Initial writeValue and ngOnChanges call onModelChange before it is defined.
			this.onModelChangePromise.then(() => this.updateModel());
		}
	}

	updateModel() {
		const element = this.el.nativeElement;
		if (!element.inputmask){
			return false;
		}
		const placeholder = element.inputmask.opts.placeholder;
		const unmaskedValue = element.inputmask.unmaskedvalue();
		const maskedValue = element.value.split(placeholder).join('');
		const value = this.uiMaskInModel ? maskedValue : unmaskedValue;
		if (this.lastUnmaskedValue === unmaskedValue) {
			return;
		}
		this.lastUnmaskedValue = unmaskedValue;
		const isComplete = this.el.nativeElement.inputmask.isComplete();
		// NOTE: Make mask function like ajs ui-mask. There is only a model value if the mask is complete
		this.onModelChange(isComplete ? value : '');
	}

	fixAjsUiMaskOptionalChar(value: string) {
		return value?.replace(/[?](.)/g, '$1{0,1}');
	}

	maskValidator: ValidatorFn = (): ValidationErrors | null => {
		if (this.el.nativeElement.inputmask.isComplete()) {
			return null;
		}
		if (this.el.nativeElement.inputmask.unmaskedvalue() === '') {
			return null;
		}
		return { incomplete: true };
	};

	validate(control: AbstractControl): ValidationErrors | null {
		return this.uiMaskValidation && this.maskValidator
			? this.maskValidator(control)
			: null;
	}
}


/*[uiMask]="'LLL-9{1,3}'"
      [uiMaskInModel]="true"
      [uiMaskValidation]="true"
      [uiMaskOptions]="UI_MASK_OPTIONS"
      formControlName="test"
      */
