<div class="container930">
	<h2>{{ "account.delete_account_question" | translate }}</h2>

	<p>
		{{ "account.delete_account_content" | translate }}
	</p>

	<p>Type '{{ "account.delete_account_check_string" | translate }}'</p>

	<input
		type="text"
		class="textField"
		#deleteText
		(keyup)="onKey(deleteText.value)"
	/>

	<div class="">
		<div class="container containerSeven">
			<div class="row">
				<div class="col-6">
					<a
						mat-raised-button
						class="deleteBtn"
						(click)="closePrompt()"
						>{{ "account.cancel" | translate }}</a
					>
				</div>
				<div class="col-6">
					<button
						mat-raised-button
						class="deleteBtn inverted"
						(click)="deleteUser()"
						[class]="{ disabled: displayDeletePrompt }"
						[disabled]="displayDeletePrompt"
					>
						{{ "account.delete_my_account" | translate }}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
