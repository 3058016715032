import {Injectable} from '@angular/core';
import {BaseStore} from "./base-store.service";
import {HttpService} from "../services/http.service";
import {LocalStorageService} from "../services/local-storage.service";
import {TranslateService} from "@ngx-translate/core";
import {Observable, forkJoin } from "rxjs";
import {map} from 'rxjs/operators';
import {LocationStore} from "./location.service";
import $ from "jquery";

declare var window;

@Injectable({
	providedIn: 'root'
})
export class SettingsStore extends BaseStore {

	private groupId: number;
	private alert: string; // Message
	private requestsCount: number = 0;
	private settings: any;
	private showNav: boolean = false;
	private topBarSettings = {};
	private orderMethods = null;
	private showBottomBar:boolean = true;
	private isBackButtonEvent = false;

	constructor(private http: HttpService, private storage: LocalStorageService, private translate: TranslateService, private location: LocationStore) {
		super();
	}

	//######################################################
	//getters
	getAlert() {
		return this.alert;
	}

	getPageLoader() {
		return this.requestsCount;
	}


	getGroupId() {
		return this.groupId;
	}

	getSettings() {
		return this.settings;
	}

	getGroupName() {
		return this.settings.group_name;
	}

	getTopBarSettings() {
		return this.topBarSettings;
	}

	getShowNav() {
		return this.showNav;
	}

	getOrderMethods() {
		return this.orderMethods;
	}

	getShowBottomBar(){
		return this.showBottomBar;
	}

	getBackButtonEvent(){
		return this.isBackButtonEvent;
	}

	//######################################################
	//Mutations
	private setAlert(val) {
		this.alert = val;
	}

	private setPageLoader(val: number) {
		this.requestsCount += val;
		if (this.requestsCount < 0) {
			this.requestsCount = 0;
		}
	}


	private setGroupId(val) {
		this.groupId = val;
	}

	private setSettings(val) {
		this.settings = val;
	}

	private setOrderMethods(val) {
		this.orderMethods = val;
	}

	private setShowNav(val) {
		this.showNav = val;
	}

	private setShowBottomBar(val) {
		this.showBottomBar = val;
	}

	private setBackButtonEvent(val){
		this.isBackButtonEvent = val;
	}

	private setTopBar(val) {
		if (val.title || val.no_translate_title) {
			val.title = val.no_translate_title || this.translate.instant(val.title)
		}
		const newVals = {
			active: true,
			title: "",
			no_translate_title: "",
			showBag: false,
			showX: false,
			showAddressTime: false,
			showSearchPosition: false,
			showBackButton: true,
			...val
		};
		this.topBarSettings = newVals;
	}

	//######################################################
	//actions
	public loadSettings(callback?: Function) {
		let arr = [];
		arr.push(this.http.get(`/groups/${this.groupId}/settings/app`))
		if (!this.orderMethods){
			// if first settings not loaded load them
			arr.push(this.http.get(`/groups/${this.groupId}/settings/orders`))
		}
		forkJoin(arr).subscribe( (res:any) => {
			this.commit("setSettings",res[0]);
			callback?.(res[0])
			if (res[1]){
				this.commit("setOrderMethods", res[1].order_methods);
				this.location.commit("setBusinesses", res[1].businesses);
				//Load Colors
				this.loadColorsAndFonts();

			}
		});
	}

	loadColorsAndFonts(){

		document.title = this.settings.group_name;
		let icon:any = document.getElementById("asocFavIcon");
		icon.href = this.settings.group_logo;

		const c = this.settings.colors;
		document.body.className += " " + c.color_theme;

		const styles: any = [
			// { name: 'color-1', value: c.text_colors.dark_primary },
			// { name: 'color-3', value: c.text_colors.dark_primary },
			// { name: 'color-4', value: c.text_colors.tertiary },
			// { name: 'color-5', value: c.text_colors.tertiary },
			// { name: 'color-6', value: c.text_colors.light_secondary },
			// { name: 'color-7', value: c.text_colors.light_secondary },
			// { name: 'color-8', value: c.background_colors.navigation_theme },
			// { name: 'color-2', value: c.main_theme_colors.primary },
			// { name: 'button-1', value: c.button_colors.button1_background },
			// { name: 'button-text-1', value: c.button_colors.button1_text },
			// { name: 'button-2', value: c.button_colors.button2_background },
			// { name: 'button-text-2', value: c.button_colors.button2_text },
			{ name: 'font-headline', value: c.fonts.headline, noHash: true },
			{ name: 'font-body', value: c.fonts.body, noHash: true  },
			{ name: 'font-button', value: c.fonts.button, noHash: true  },

		];
		styles.forEach(data => {
			document.body.style.setProperty(`--${data.name}`, data.noHash ? `'${data.value}'` : `#${data.value}`);
		});



		if(window.cordova) {
			document.body.className += " cordova"
			window.StatusBar.styleLightContent();
		}

		if(window.cordova?.platformId == 'android') {
			const androidColor = getComputedStyle(document.body).getPropertyValue('--color-2').trim();
			window.StatusBar.backgroundColorByHexString(androidColor);
			window.NavigationBar.backgroundColorByHexString(androidColor, false);
		}

		let fonts = [c.fonts.headline,c.fonts.body,c.fonts.button];
		const fontsURL = "https://fonts.googleapis.com/css?family=" + fonts.join("|")
		let  fontElement = document.createElement('link');
		fontElement.setAttribute('href',fontsURL)
		fontElement.setAttribute('rel','stylesheet')
		fontElement.setAttribute('type','text/css')
		document.head.appendChild(fontElement);
	}

	public topBar(val) {
		this.commit("setTopBar", val);
	}

	public flashWarning(val, use_translate:boolean = false) {
		this.commit("setAlert", use_translate  ?  this.translate.instant(val) : val);
	}

	public getParameterByName(name, url = window.location.href) {
		name = name.replace(/[\[\]]/g, '\\$&');
		let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	public initScrollErrorForm(){
		const delay = 0;
		const offset = 150;

		document.addEventListener('invalid', function (e) {
			$(e.target).addClass("invalid");
			$('.page').animate({scrollTop: $($(".invalid")[0]).offset().top - offset}, delay);
		}, true);
		document.addEventListener('change', function (e) {
			$(e.target).removeClass("invalid")
		}, true);
	}

	public loadOrderMethods() {
		//old code
		// var orderMethods: Observable<{}>;
		// orderMethods = this.http.get(`/groups/${this.groupId}/settings/orders`)
		// .pipe(
		// 	map(returnOrderMethods))

		// function getFilteredOrderMethods(unfilteredMethods) {
		// 	return Object.entries(unfilteredMethods.order_methods.regular).filter(([key, value]) => key != 'for_here' && key != 'to_go')
		// }

		// function returnOrderMethods(filteredMethods) {
		// 	let result = {}
		// 	result = Object.fromEntries(getFilteredOrderMethods(filteredMethods))
		// 	return result;
		// }
		// return orderMethods
		//new code

		var orderMethods: Observable<{}>;
		orderMethods = this.http.get(`/groups/${this.groupId}/settings/orders`)
		.pipe(
			map(returnOrderMethods))

		function getFilteredOrderMethods(unfilteredMethods) {
			return Object.entries(unfilteredMethods.order_methods.regular).filter(([key, value]) => key != 'for_here' && key != 'to_go' && key != 'for_here_patio')
		}

		function returnOrderMethods(filteredMethods) {
			let result = {}
			
			result = Object.fromEntries(getFilteredOrderMethods(filteredMethods))
			
		return	filteredMethods.order_methods
		}
		return orderMethods
	}

}
