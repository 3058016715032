import {Component, OnInit} from '@angular/core';
import {Store} from "../../store/store.service";
import {Location} from "@angular/common";
import {Router} from "@angular/router";

@Component({
	selector: 'app-top-bar',
	templateUrl: './top-bar.component.html'
})
export class TopBarComponent implements OnInit {

	public settings: any = this.store.settings.getTopBarSettings();
	public appSettings: any = this.store.settings.getSettings();
	public cart = this.store.cart.getCartObject();
	public bAddress = this.cart.business?.address;

	constructor(public store: Store, private location: Location, private router: Router) {
	}

	ngOnInit(): void {
		this.store.settings.getStream$().subscribe((data) => {
			if (data.name === "setTopBar") {
				this.settings = this.store.settings.getTopBarSettings();
				this.bAddress = this.cart.business?.address;
			}
		});
	}

}
