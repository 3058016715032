import {Injectable} from '@angular/core';
import {BaseStore} from "./base-store.service";
import {LocalStorageService} from "../services/local-storage.service";
import * as moment from "moment";
import $ from "jquery"

declare var window;

@Injectable({
	providedIn: 'root'
})
export class LocationStore extends BaseStore {

	private geoPosition:any = {};
	private fullHeight:boolean = false; // SideBar for location page
	private mapSettings: any = {
		center: null,
		selectedBusinessID: null,
		business: null
	}

	private businesses:any;
	private availableBusinessesIDs:any;
	private tempAddress:any;
	public isClosed = false;

	constructor(private storage: LocalStorageService) {
		super();
	}

	getGeoPosition() {
		return this.geoPosition;
	}

	getMapSettings() {
		return this.mapSettings;
	}

	getFullHeight() {
		return this.fullHeight;
	}

	getBusinesses() {
		return this.businesses;
	}

	getTempAddress() {
		return this.tempAddress;
	}

	getBusinessByID(id) {
		return this.businesses?.find(el => el.business_id == id);
	}

	getOrderBusinesses (all = false) {
		if (this.availableBusinessesIDs?.length && !all){
			return this.businesses.filter(el => this.availableBusinessesIDs.includes(parseInt(el.business_id)));
		}else{
			return this.businesses;
		}

	}

	//######################################################
	//Mutations
	private setGeoPosition(val) {
		this.geoPosition = val;
		this.mapSettings.center = val;
		this.storage.setItem("lastPosition", val);
	}

	private setFullHeight(val) {
		this.fullHeight = val;
	}

	private setTempAddress(val) {
		this.tempAddress = val;
	}

	private setAvailableBusinessesIDs(val) {
		Array.isArray(val) && val.map((el,i)=>val[i]=parseInt(val[i]));
		this.availableBusinessesIDs = val;
	}

	private setBusinesses(val) {
		let arr = []
		if (val){
			Object.keys(val).map(k=> {
				val[k].business_id = k
				val[k].isOpen = isOpen.bind(val[k]);
				arr.push(val[k]);
			});
		}
		this.businesses = arr;
		if (window.self !== window.top){
			return false
		}
		this.initGeoLocation();
	}

	//######################################################
	//actions
	initGeoLocation() {
		if (window.navigator.geolocation) {
			window.navigator.geolocation.getCurrentPosition(
				(position) => {
					this.commit("setGeoPosition", {lat: position.coords.latitude, lng: position.coords.longitude});
					this.calcBusinessDistances();
				},
				(error) => {
					//Default Location if user block gps
					this.commit("setGeoPosition", {lat: this.businesses?.[0].address.lat, lng: this.businesses?.[0].address.lon, blocked: true})
					this.calcBusinessDistances();
				}
			);
		}
	}

	selectBusiness(b) {
		if (b === -1) {
			this.mapSettings.center = {...this.geoPosition};
			this.mapSettings.selectedBusinessID = null
		} else {
			this.mapSettings.center = {lat: Number(b.address.lat), lng: Number(b.address.lon)};
			this.mapSettings.selectedBusinessID = b.business_id
		}

		setTimeout(()=>{
			if (!this.fullHeight && $("#businessStore" + b.business_id).length){
				$("#sideBar").animate({
					scrollTop: $('#sideBar').scrollTop() + ($("#businessStore" + b.business_id).offset().top - 450)
				}, 500);
			}
		})
	}

	calcBusinessDistances(){
		if (this.geoPosition.blocked){
			return false;
		}
		this.businesses.map((el) => {
			el.distance = this.calcDistance(this.geoPosition.lat, this.geoPosition.lng, el.address.lat, el.address.lon).toFixed(1)
		})
		this.businesses.sort((a, b) => a.distance - b.distance)
	}

	//Helper
	calcDistance(lat1, lon1, lat2, lon2, unit = "N") {
		let radlat1 = Math.PI * lat1 / 180;
		let radlat2 = Math.PI * lat2 / 180;
		let theta = lon1 - lon2;
		let radtheta = Math.PI * theta / 180;
		let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		dist = Math.acos(dist);
		dist = dist * 180 / Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit == "K") {
			dist = dist * 1.609344;
		}
		if (unit == "N") {
			dist = dist * 0.8684;
		}
		return dist
	}

	generateAddress(e) {
		let ShouldBeComponent = {
			home: ["street_number"],
			postal_code: ["postal_code"],
			street: ["street_address", "route"],
			state: [
				"administrative_area_level_1",
				"administrative_area_level_2",
				"administrative_area_level_3",
				"administrative_area_level_4",
				"administrative_area_level_5"
			],
			city: [
				"locality",
				"sublocality",
				"sublocality_level_1",
				"sublocality_level_2",
				"sublocality_level_3",
				"sublocality_level_4"
			],
			country: ["country"]
		};
		let address = {
			home: "",
			postal_code: "",
			street: "",
			state: "",
			city: "",
			country: ""
		};
		e.address_components.forEach(component => {
			for (let shouldBe in ShouldBeComponent) {
				if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
					address[shouldBe] = component.long_name;
				}
			}
		});

		return {
			//"business_id" : this.businessId,
			"lat": e.geometry.location.lat(),
			"lng": e.geometry.location.lng(),
			"address_components": {
				"street": "",
				"building": "",
				"zone": "",
				"address2": "",
				"address": `${address.home} ${address.street}`,
				"city": address.city,
				"state": address.state,
				"zip": address.postal_code,
				"comment_customer" : "",
				"lat": e.geometry.location.lat(),
				"lng": e.geometry.location.lng(),
			},
			"comment_customer": ""
		};
	}
}



const isOpen = function () {
	const now = moment();
	const day = moment().format("ddd");
	if (this.operation_hours[day.toLowerCase()][0].operation_hours === "Closed"){
		return false;
	}else{
		const todayMax = moment(this.operation_hours[day.toLowerCase()][0].end, "HH:mm:ss");
		const todayMin = moment(this.operation_hours[day.toLowerCase()][0].start, "HH:mm:ss");
		if (todayMax.isBefore(now) && todayMin.isAfter(now)){
			return true;
		}
	}
	return false;
}
