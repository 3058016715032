<div class="container mb-4">
	<h3 class="d-flex justify-content-between align-items-end mb-2 mb-md-4 mt-md-5 lineHeight1">
		<span>{{"home.rewards" | translate}}</span>
		<a routerLink="/rewards" class="color7 h5" [class]="{'d-none': dummy}">{{ "home.see_more" | translate }}</a>
	</h3>
	<div class="position-relative">
		<app-dummy-placeholder *ngIf="dummy"></app-dummy-placeholder>
		<div class="container930" [class.dummyContent]="dummy">
			<div class="offersList">
				<div *ngFor="let offer of offers | slice:0:2" class="offer border rounded-lg text-center px-2">
					<div class="box">
						<div class="mt-2">
							<h4 class="mb-2">{{offer.offer_title}}</h4>
							<div class="smallText description"[innerHTML]="offer.offer_description | striphtml"></div>
						</div>
						<!--<a mat-raised-button routerLink="/order-type">{{"home.claim" |translate}}</a>-->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
