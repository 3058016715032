import { Component, Input, OnInit } from '@angular/core';
import { Store } from '../../../store/store.service';

@Component({
	selector: 'app-active-order',
	templateUrl: './active-order.component.html',
	styleUrls: ['active-order.component.scss'],
})
export class ActiveOrderComponent implements OnInit {
	@Input() currency: any;
	orders: any = [];
	isActive = false;

	constructor(public store: Store) {}

	ngOnInit(): void {
		if(this.store.auth.isLoggedIn()) {
			this.loadActiveOrders();
		}
	}

	loadActiveOrders(){
		this.store.auth.loadOrderHistory().subscribe((orders) =>{
			let i;
		   for (i of orders){
			   if (i.is_active) {
				   this.isActive = true;
				   this.orders.push(i);
			   }
		   }
	   });
	}
}
