<div class="">
	<mat-checkbox class="mt-2" [(ngModel)]="gcard.auto_recharge_enabled">
		<h3 class="my-2">{{ "gc.auto_reload_card" | translate }}</h3>
	</mat-checkbox>

	<div class="autoreload" *ngIf="gcard.auto_recharge_enabled">
		<div class="w-100">
			<label>
				<div class="mb-2">{{ "gc.auto_threshold" | translate }}</div>
				<mat-form-field appearance="outline">
					<input
						matInput
						type="number"
						inputmode="decimal"
						min="0.00"
						step="0.50"
						[value]="gcard.auto_recharge_threshold"
						(focus)="onFocus($event)"
						(input)="
							changeAmmount($event, 'auto_recharge_threshold')
						"
						(blur)="
							customTipDeselect($event, 'auto_recharge_threshold')
						"
						[placeholder]="'gc.auto_threshold' | translate"
						required
					/>
				</mat-form-field>
			</label>
		</div>
		<div class="w-100">
			<label>
				<div class="mb-2">{{ "gc.auto_amount" | translate }}</div>
				<mat-form-field appearance="outline">
					<input
						matInput
						type="number"
						inputmode="decimal"
						min="0.00"
						step="0.50"
						[value]="gcard.auto_recharge_amount"
						(focus)="onFocus($event)"
						(input)="changeAmmount($event, 'auto_recharge_amount')"
						(blur)="
							customTipDeselect($event, 'auto_recharge_amount')
						"
						[placeholder]="'gc.auto_amount' | translate"
						required
					/>
				</mat-form-field>
			</label>
		</div>
	</div>

	<mat-checkbox
		class="my-4"
		required
		*ngIf="gcard.terms_conditions_url"
		[(ngModel)]="gcard.agreed_terms_conditions"
	>
		<h4>
			{{ "gc.i_agree" | translate }}
			<a
				target="_system"
				[href]="gcard.terms_conditions_url"
				class="text-underline"
				>{{ "gc.term_link" | translate }}</a
			>
		</h4>
	</mat-checkbox>
</div>
