<div class="page ">
	<div class="page noTopPadding" *ngIf="data">
		<div class="container690">
			<img
				*ngIf="data.featured_image"
				class="w-100 mb-4"
				[src]="data.featured_image"
			>
			<app-specials *ngIf="data.featured_content.length" [list]="data.featured_content"></app-specials>

			<!--Previous Orders-->
			<app-active-order [currency]="data.currency"></app-active-order>

			<app-previous-order *ngIf="data.show_previous_order" [order]="data.previous_order" [currency]="data.currency" [dummy]="data.dummy"></app-previous-order>

			<app-loyalty-profile *ngIf="data.show_loyalty_profile && data.loyalty_profile" [data]="data.loyalty_profile" [dummy]="data.dummy"></app-loyalty-profile>

			<app-offers-list *ngIf="data.show_offers && data.offers?.length" [offers]="data.offers" [dummy]="data.dummy"></app-offers-list>

			<app-favourite-location *ngIf="data.show_nearest_location" [favorite]="data.favorite_business"></app-favourite-location>

			<div class="container mb-4" *ngIf="data.show_ewallet && data.gift_cards?.length">
				<h3 class="mb-2 mb-md-4 mt-md-5 justify-content-between d-flex align-items-end lineHeight1">
					{{'home.saved_gift_cards' | translate}}
					<a routerLink="/payment-options" class="color7 h5" [class]="{'d-none': data.dummy}">{{ "home.view_add_link" | translate }}</a>
				</h3>
				<div class="position-relative">
					<app-dummy-placeholder *ngIf="data.dummy"></app-dummy-placeholder>
					<div class="container930" [class.dummyContent]="data.dummy">
						<app-gift-card-payment
							hideCheckoutSection="true"
							[list]="data.gift_cards"
						></app-gift-card-payment>
					</div>
				</div>
			</div>

			<div class="container mb-4" *ngIf="data.show_credit_cards && data.credit_cards?.length">
				<h3 class="mb-2 mb-md-4 mt-md-5">{{'home.saved_credit_cards' | translate}}</h3>
				<div class="position-relative">
					<app-dummy-placeholder *ngIf="data.dummy"></app-dummy-placeholder>
					<div class="container930" [class.dummyContent]="data.dummy">
						<app-payment-section
							title=""
							hideNavs="true"
							asSlider="true"
							noActive="true"
							[list]="data.credit_cards"
						></app-payment-section>
					</div>
				</div>
			</div>

			<div class="container mb-4" *ngIf="data.show_buy_gift_card_box">
				<h3 class="mb-2 mb-md-4 mt-md-5">{{ "home.buy_gift_card" | translate }}</h3>
				<div class="container930">
					<div class="border rounded-lg text-center p-4">
						<div class="text-left clearfix mb-3">
							<div class="svgIcons giftCardHand"></div>
						</div>
						<h3 class="mb-3">{{ "home.buy_gift_card_text" | translate }}</h3>
						<div class="maxW160 mx-auto"><a mat-raised-button routerLink="/gift-cards">{{ "home.buy_gift_card_button" | translate }}</a></div>
					</div>
				</div>
			</div>

			<!--Member Card-->
			<div class="container mb-4" *ngIf="data.show_member_card && user">
				<h3 class="mb-2 mb-md-4 mt-md-2">{{ "home.member_card" | translate }}</h3>
				<div class="position-relative">
					<app-dummy-placeholder *ngIf="data.dummy"></app-dummy-placeholder>
					<div class="container930" [class.dummyContent]="data.dummy">
						<div class="border rounded-lg text-center p-4">
							<h3 class="mb-3">Click here to scan your member card</h3>
							<div class="maxW200 mx-auto"><a mat-raised-button routerLink="/member-card">{{ "home.member_card" | translate }}</a></div>
						</div>
					</div>
				</div>
			</div>

			<div class="py-5"></div>
		</div>
	</div>

	<div class="buttonsRow " *ngIf="data.show_previous_order">
		<div class="container containerSeven">
			<div class="row">
				<div class="col-6 text-right">
					<a mat-raised-button routerLink="/order-type">{{ "home.order_now" | translate }}</a>
				</div>
				<div class="col-6">
					<a mat-raised-button class="inverted" routerLink="/orders">{{ "home.reorder" | translate }}</a>
				</div>
			</div>
		</div>
	</div>

</div>
