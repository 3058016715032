import {Pipe, PipeTransform} from '@angular/core';

//Instead of object it will return array
@Pipe({
	name: 'sortObject'
})
export class SortObjectPipe implements PipeTransform {

	transform(value: unknown, ...args: any[]): unknown {
		let keyArr: any[] = Object.keys(value),
			dataArr = [],
			keyName: string = args[0];

		keyArr.forEach((key: any) => {
			dataArr.push(value[key])
		});

		dataArr.sort((a: Object, b: Object): number => {
			return a[keyName] > b[keyName] ? 1 : -1;
		});

		return dataArr;
	}

}
