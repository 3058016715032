<div class="specials pb-2">
	<div class="container">
		<h3 class="mb-2">{{"home.specials" | translate}}</h3>
	</div>

	<ngx-slick-carousel [config]="slideConfig"
						(beforeChange) = "beforeChange($event)"
						(afterChange) = "afterChange($event)">
		<div
			ngxSlickItem
			class="item"
			*ngFor="let item of list"
			(click)="goToURL(item.action_url)"
		>
			<img class="w-100" [src]="item.image">
			<h3 class="mt-2">{{item.title}}</h3>
			<h6 class="color2">{{item.text}}</h6>
			<div class="mt-2"></div>
		</div>
	</ngx-slick-carousel>
</div>

