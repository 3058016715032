<div class="mt-4">
	<ngx-qrcode
		elementType="url"
		[value]="sessionToken"
		errorCorrectionLevel="H"
		[width]="250"
	>
	</ngx-qrcode>
	<div>
		{{ sessionToken }}
	</div>
	<h5 class="color-2 mt-2">{{ "rewards.scan_note" | translate }}</h5>
	<div class="member-padding-top">Member ID:</div>
	<div>{{ user?.member_id }}</div>
</div>
