import {Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'app-level-progress',
	templateUrl: './level-progress.component.html'
})
export class LevelProgressComponent implements OnInit {

	@Input() percentage;

	constructor() {
	}

	ngOnInit(): void {
	}

}
