import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatNativeDateModule, MatRippleModule} from "@angular/material/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {MatSelectModule} from "@angular/material/select";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatGoogleMapsAutocompleteModule} from "@angular-material-extensions/google-maps-autocomplete";
import {LocationSearchBoxComponent} from './location-search-box/location-search-box.component';
import {PhonePipe} from '../pipes/phone.pipe';
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {FormsModule} from "@angular/forms";
import {SlickCarouselModule} from "ngx-slick-carousel";
import {StripHtmlPipe} from '../pipes/strip-html.pipe';
import {NavComponent} from './top-bar/nav/nav.component';
import {RouterModule} from "@angular/router";
import {PaymentSectionComponent} from "./payment-section/payment-section.component";
import {GiftCardPaymentComponent} from './gift-card-payment/gift-card-payment.component';
import {GiftPromtComponent} from './gift-promt/gift-promt.component';
import {MatRadioModule} from "@angular/material/radio";
import {MemberCardBarcodeComponent} from './member-card-barcode/member-card-barcode.component';
import {GiftAmountComponent} from "./gift-amount/gift-amount.component";
import {LevelProgressComponent} from './level-progress/level-progress.component';
import {MomentPipe} from '../pipes/date-format.pipe';
import {GiftCardAutoRechargeFormComponent} from './gift-card-auto-recharge-form/gift-card-auto-recharge-form.component';
import {ProfileFieldsComponent} from "./profile-fields/profile-fields.component";
import {UiMaskDirective} from "../directives/ui-mask.directive";
import {BottomBarComponent} from './bottom-bar/bottom-bar.component';
import {SortObjectPipe} from '../pipes/sort-object.pipe';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { MatGridListModule } from '@angular/material/grid-list';
import { DeleteAccountPromptComponent } from './delete-account-prompt/delete-account-prompt.component';

@NgModule({
	declarations: [
		LocationSearchBoxComponent,
		SortObjectPipe,
		PhonePipe,
		StripHtmlPipe,
		NavComponent,
		PaymentSectionComponent,
		GiftCardPaymentComponent,
		GiftPromtComponent,
		GiftAmountComponent,
		MemberCardBarcodeComponent,
		LevelProgressComponent,
		MomentPipe,
		GiftCardAutoRechargeFormComponent,
		ProfileFieldsComponent,
		UiMaskDirective,
		BottomBarComponent,
		DeleteAccountPromptComponent,
	],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDatepickerModule,
        MatIconModule,
        MatInputModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatMomentDateModule,
        MatTooltipModule,
        MatSelectModule,
        MatCheckboxModule,
        MatRippleModule,
        MatRadioModule,
        ReactiveFormsModule,
        TranslateModule,
        MatGoogleMapsAutocompleteModule,
        NgxMaterialTimepickerModule,
        FormsModule,
        SlickCarouselModule,
        RouterModule,
        NgxQRCodeModule,
        MatGridListModule,
    ],
	exports: [
		MatButtonModule,
		MatDatepickerModule,
		MatIconModule,
		MatInputModule,
		MatNativeDateModule,
		MatFormFieldModule,
		MatMomentDateModule,
		MatTooltipModule,
		MatSelectModule,
		MatCheckboxModule,
		MatRippleModule,
		MatRadioModule,
		ReactiveFormsModule,
		MatGoogleMapsAutocompleteModule,
		TranslateModule,
		LocationSearchBoxComponent,
		SortObjectPipe,
		PhonePipe,
		StripHtmlPipe,
		NgxMaterialTimepickerModule,
		FormsModule,
		SlickCarouselModule,
		NavComponent,
		PaymentSectionComponent,
		GiftCardPaymentComponent,
		GiftPromtComponent,
		RouterModule,
		GiftAmountComponent,
		MemberCardBarcodeComponent,
		LevelProgressComponent,
		MomentPipe,
		ProfileFieldsComponent,
		GiftCardAutoRechargeFormComponent,
		UiMaskDirective,
		BottomBarComponent,
	],
})
export class SharedModule {}
