<div class="page welcomePage  p-0">
	<div class="container text-center pb-5  min-h-100 d-flex flex-column position-relative" *ngIf="settings">
		<div class="welBg"></div>
		<div class="row">
			<div class="col-12 mt-5">
				<img class="img-fluid logo" [src]="settings.group_logo">
			</div>
		</div>
		<div class="row mt-auto ">
			<div class="col-12 mt-3">
				<div class="containerSeven">
					<a class="mt-5 mb-3 roundWhite" mat-raised-button routerLink="/signup">
						{{'account.join' | translate}}
						{{ settings.name }}
					</a>
					<a class="colorWhite" mat-button routerLink="/login">{{'account.signin' | translate}}</a>
					<a class="colorWhite text-transform-none" mat-button routerLink="/home">{{'account.continue_as_guest' | translate}}</a>
				</div>
			</div>
		</div>
	</div>
</div>
