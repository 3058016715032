import { Component, OnInit } from '@angular/core';
import { Store } from '../../store/store.service';

@Component({
	selector: 'app-member-card-barcode',
	templateUrl: './member-card-barcode.component.html',
})
export class MemberCardBarcodeComponent implements OnInit {
	user: any;
	sessionToken: any;

	constructor(private store: Store) {}

	ngOnInit(): void {
		this.user = this.store.auth.getUser();
		this.store.auth.loadSessionToken(
			(data) => (this.sessionToken = data.body.token)
		);
	}
}
