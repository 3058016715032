import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Store} from "../../store/store.service";

@Component({
	selector: 'app-promt',
	templateUrl: './promt.component.html'
})
export class PromtComponent implements OnInit {

	settings: any = this.store.promt.getSettings();
	sub: Subscription;
	show = false;

	constructor(private store: Store) {
	}

	ngOnInit(): void {
		this.sub = this.store.promt.getStream$().subscribe(()=>{
			this.show = true;
			this.settings = this.store.promt.getSettings();
			this.settings.buttonConfirm = this.settings.buttonConfirm ?? this.store.translate.instant("promt.confirm");
			this.settings.buttonCancel = this.settings.buttonCancel ?? this.store.translate.instant("promt.cancel");
		});
	}

	success(e){
		e.stopPropagation();
		this.settings?.success?.();
		this.show = false;
	}

	deny(e){
		console.log("working")
		e.stopPropagation();
		this.settings?.deny?.()
		this.show = false;
	}

}

/*
Example how to use
this.store.promt.show({
	content: "Hello",
	success: () => {
		console.log("sucsess");
	},
	deny: () => {
		console.log("deny");
	},
	buttonConfirm: "Bla",
	buttonCancel: "Bla Bla",
})*/
