import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from "../../store/store.service";
import {Subscription} from "rxjs";

@Component({
	selector: 'app-gift-card-payment',
	templateUrl: './gift-card-payment.component.html'
})
export class GiftCardPaymentComponent implements OnInit, OnDestroy {

	@Input() title;
	@Input() hideList;
	@Input() hideCheckoutSection;
	@Input() list;
	@Input() hideNavs;
	@Input() hideHR;
	@Input() enable_cards_selections = false;
	cart = this.store.cart.getCartObject();
	user = this.store.auth.getUser();
	giftCards: any;
	gcTotal: number = 0;
	sub: Subscription;

	constructor(private store: Store) {
	}

	ngOnInit(): void {
		this.initCards();
		this.sub = this.store.giftPromt.getStream$().subscribe(data => {
			if (data.name === "updateGiftCards") {
				this.initCards(true);
			}
		})
	}

	initCards(refresh = false) {
		this.isCardsSelected() && this.toggleGCards();
		if (this.list && !refresh) {
			this.giftCards = this.list;
		} else {
			if (this.user){
				this.store.gc.loadUserGiftCards({
					user_id: this.user?.member_id,
					callback: data => {
						this.giftCards = data;
						this.calcTotal();
					}
				});
			}else{
				this.giftCards = this.cart.tempGCards;
				this.calcTotal();
			}
		}

	}

	calcTotal(){
		this.gcTotal = 0;
		this.giftCards?.map(gc => {
			this.gcTotal += parseFloat(gc.balance);
		});
	}

	removeCard(e, index) {
		e.stopPropagation();
		this.store.promt.show({
			content: this.store.translate.instant("payment.delete_gift_card_promt"),
			success: () => {
				if (this.user){
					this.store.gc.deleteGiftCard({
						id: this.giftCards[index].card_id,
						user_id: this.user?.member_id,
						callback: () => {
							this.giftCards.splice(index, 1);
							this.calcTotal();
						}
					})
				}else{

					this.giftCards.splice(index, 1);
					this.calcTotal();
					this.store.cart.commit("updateCartDate");
				}

			}
		})
	}

	showCardInfo(gc) {
		this.store.giftPromt.show(gc);
	}

	toggleGCards( e?:any ) {
		e?.preventDefault();
		if (this.isCardsSelected()) {
			Object.keys(this.cart.giftCards).forEach(k => {
				delete this.cart.giftCards[k];
			})
		} else {
			this.giftCards.map(gc => {
				this.selectCard(gc);
			});
		}
	}

	selectCard(gc) {
		if (this.cart.giftCards[gc.card_id]) {
			delete this.cart.giftCards[gc.card_id];
		} else {
			this.cart.giftCards[gc.card_id] = {
				type: this.user ? "gc_id" : "gc_info",
				id: gc.card_id,
				balance: gc.balance,
				number: gc.card_number,
				cvv: gc.cvv,
			};
		}
	}

	selectedTotal() {
		return Object.keys(this.cart.giftCards).reduce( (total,k) => total += parseFloat(this.cart.giftCards[k].balance), 0).toFixed(2);
	}

	isCardsSelected() {
		return !!Object.keys(this.cart.giftCards).length
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}

}
