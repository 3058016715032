import { Component, OnInit, HostListener } from '@angular/core';
import { Store } from '../../../store/store.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

declare var window;

@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
})
export class NavComponent implements OnInit {
	@HostListener('document:keydown.escape', ['$event'])
	onKeydownHandler(): void {
		this.close();
	}
	versionNo:string
	public user = this.store.auth.getUser();
	public showNav = this.store.settings.getShowNav();
	public params = this.store.settings.getSettings();
	displayDeletePrompt = false
	lb = window.cordova ? '</br>' : '%0A';
	percent = window.cordova ? '%' : '%25';
	public navList = [
		{ title: 'our_menu', url: '/order-type', flag: 'show_our_menu' },
		{
			title: 'locations',
			url: '/locations/all',
			flag: 'show_find_location',
		},
		{ title: 'rewards', url: '/rewards', flag: 'show_rewards' },
		{ title: 'profile', url: '/profile', flag: 'show_profile_settings' },
		{ title: 'orders', url: '/orders', flag: 'show_recent_orders' },
		{ title: 'buy_gift_card', url: '/gift-cards', flag: 'show_egift' },
		{ title: 'buy_ewallet', url: '/buy-ewallet', flag: 'show_ewallet' },
	];

	// public deleteEmail = {
	// 	to: `${this.store.settings.getContactEmail()}`,
	// 	subject: 'Delete Data Request',
	// 	body: `Hello. I would to delete my account. Below is my account information:
	// 	${this.lb} Name:
	// 	${
	// 		this.store.auth.isLoggedIn()
	// 			? this.user.first_name + ' ' + this.user.last_name
	// 			: ''
	// 	}
	// 	${this.lb} Member ID:
	// 	${this.store.auth.isLoggedIn() ? this.user.id : ''}`,
	// 	isHtml: true,
	// };

	constructor(
		public store: Store,
		private router: Router,
		private location: Location,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.versionNo = window.asoc.versionNo
		this.store.settings.getStream$().subscribe((data) => {
			if (data.name === 'setShowNav') {
				this.showNav = this.store.settings.getShowNav();
				if (this.showNav) {
					this.router.navigate([], {
						queryParams: { showNav: 1 },
						queryParamsHandling: 'merge',
					});
				}
			}
			if (data.name === 'setHomePageSet') {
				this.params = this.store.settings.getSettings();
			}
		});
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				// So it called once
				if (!this.route.snapshot.queryParams.showNav) {
					this.store.settings.commit('setShowNav', false);
				}
			}
		});
	}

	// openEmail(email) {
	// 	if (window.cordova) {
	// 		window.cordova.plugins.email.open(email);
	// 	} else {
	// 		window.location.href =
	// 			'mailto:' +
	// 			email.to +
	// 			'?body=' +
	// 			email.body +
	// 			'&subject=' +
	// 			email.subject;
	// 	}
	// }

	deleteAccount() {
		this.store.auth.openDeleteUserPrompt()
		this.store.auth.deleteAccountPromptChange.subscribe(change => {
			if(change){
				if (change === "Open"){
					this.displayDeletePrompt = true
				}
				if (change === "Close"){
					this.displayDeletePrompt = false
				}
			}
		})
	}

	openExternal(url) {
		if (window.cordova && window.cordova.InAppBrowser) {
			window.cordova.InAppBrowser.open(
				url,
				'_blank',
				'toolbartranslucent=no'
			);
			this.store.settings.commit('setShowNav', false);
		} else {
			window.open(url, '_system');
		}
	}

	close() {
		this.store.auth.closeDeleteUserPrompt()
		this.store.settings.commit('setShowNav', false);
	}

	logout() {
		this.store.promt.show({
			content: this.store.translate.instant('account.logout_warning'),
			success: () => {
				this.store.auth.signOut();
				this.close();
			},
			buttonConfirm: this.store.translate.instant('account.logout'),
		});
	}
}
