import {Component, OnInit} from '@angular/core';
import {Store} from "../../store/store.service";

@Component({
	selector: 'app-bottom-bar',
	templateUrl: './bottom-bar.component.html'
})
export class BottomBarComponent implements OnInit {

	public settings = this.store.settings.getSettings();
	activeOrders = [];
	activeOrderCount = 0;

	constructor(private store: Store) {
	}

	ngOnInit(): void {
		if(this.store.auth.isLoggedIn()) {
			this.calcActiveOrders();
		}
	}

	openNav(){
		this.store.settings.commit('setShowNav', true)
	}

	calcActiveOrders() {
		this.store.auth.loadOrderHistory().subscribe((orders) =>{
			let i;
			for (i of orders){
				if (i.is_active) {
					this.activeOrders.push(i);
				}
			}
			this.activeOrderCount = this.activeOrders.length;
		});
	}
}
