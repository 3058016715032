<div id="promt" *ngIf="show"  (click)="deny($event)">
	<div class="w-100 d-xl-none d-lg-none d-md-none">
		<h2 class="mb-3 text-center">
			{{settings.content}}
		</h2>
		<div class="row">
			<div class="col-6">
				<a mat-raised-button (click)="success($event)">{{settings.buttonConfirm}}</a>
			</div>
			<div class="col-6">
				<a mat-raised-button  class="inverted" (click)="deny($event)">{{settings.buttonCancel}}</a>
			</div>
		</div>
	</div>
	<div class="containerSeven d-none d-md-block">
		<h2 class="mb-3 text-center">
			{{settings.content}}
		</h2>
		<div class="row">
			<div class="col-6">
				<a mat-raised-button (click)="success($event)">{{settings.buttonConfirm}}</a>
			</div>
			<div class="col-6">
				<a mat-raised-button  class="inverted" (click)="deny($event)">{{settings.buttonCancel}}</a>
			</div>
		</div>
	</div>
</div>
