import {Injectable, Injector} from '@angular/core';
import {BaseStore} from "./base-store.service";
import {SettingsStore} from "./settings.service";
import {HttpService} from "../services/http.service";
import {CartStore} from "./cart.service";

@Injectable({
	providedIn: 'root'
})
export class MenuStore extends BaseStore {

	private menu: any;
	private singleItem;
	private searchQuery: string = "";
	private cartStore: any;
	private cart: any;
	public scrollPosition = null;

	constructor(private settings: SettingsStore, private injector: Injector, private http: HttpService) {
		super();
		setTimeout(() => {
			this.cartStore = this.injector.get(CartStore);
			this.cart = this.cartStore.getCartObject();
		})
	}

	//#############################################
	//Getter
	getMenu() {
		return this.menu;
	}

	getSingleItem() {
		return this.singleItem;
	}

	getSearchQuery() {
		return this.searchQuery;
	}

	//#############################################
	//Mutations
	private setMenu(val) {
		if (val) {
			val.findCategoryById = findCategoryById.bind(val);
			val.findProductsByName = findProductsByName.bind(val);
		}
		this.menu = val;
	}

	private setSingleItem(val) {
		if (val) {
			val = val?.combo_meal_id ? this.initComboItem(val) : this.initSingleProduct(val);
		}
		this.singleItem = val;
	}

	private setSearchQuery(val) {
		this.searchQuery = val;
	}

	//#############################################
	//actions
	loadMenu({businessId, method, callback}: { businessId?: number; method?: string; callback?: Function } = {}) {
		businessId = businessId || this.cart.business.business_id;
		method = method || this.cart.orderMethod;
		this.http.get(`/businesses/${businessId}/order_methods/${method}/menu`).subscribe(data => {
			this.commit("setMenu", data);
			callback?.()
		})
	}

	loadMenuItem({menuItemId, method, businessId, callback, combo}: { menuItemId?: number; method?: string, businessId?: number; callback?: Function, combo?: boolean } = {combo: false}) {
		businessId = businessId || this.cart.business.business_id;
		method = method || this.cart.orderMethod;
		let url = `/businesses/${businessId}/order_methods/${method}/menu/items/${menuItemId}`
		if (combo) {
			url = `/businesses/${businessId}/order_methods/${method}/menu/combo_meal/${menuItemId}`;
		}
		this.http.get(url).subscribe(data => {
			this.commit("setSingleItem", data);
			callback?.()
		})
	}

	initComboItem(val) {
		if (!val.quantity) { // if has quantity it mean it existing
			val = {quantity: 1, quantityStep: 1, quantityMin: 1, ...val};
		} else {
			val = JSON.parse(JSON.stringify(val));
		}
		let modifiersIndexObj = {current: 0};
		val.isCombo = true
		val.changeQuantity = changeQuantity.bind(val);
		val.setQuantity = setQuantity.bind(val);
		val.totalComboPrice = totalComboPrice.bind(val);
		val.totalPrice = totalPrice.bind(val);
		val.validateComboProduct = validateComboProduct.bind(val);
		val.generateComboIngredients = generateComboIngredients.bind(val);
		val.main_menu.price = "0.00"
		val.main_menu = this.initSingleProduct(val.main_menu, modifiersIndexObj);
		val.option_groups.forEach(group => {
			group.active_item = group.items[0].menu_item.menu_id
			group.items.forEach((item) => {
				item.menu_item.price = item.price
				item.menu_item = this.initSingleProduct(item.menu_item, modifiersIndexObj)
			});
		})
		return val;
	}

	initSingleProduct(val, total_index = {current: 0}) {
		if (!val.quantity) { // if has quantity it mean it existing
			val = {activeModifiers: {}, quantity: 1, quantityStep: 1, quantityMin: 1, ...val, showInRed: []};
		} else {
			val = JSON.parse(JSON.stringify(val));
		}
		val.isCombo = false;
		val.totalProductPrice = totalProductPrice.bind(val);
		val.totalPrice = totalPrice.bind(val);
		val.changeQuantity = changeQuantity.bind(val);
		val.setQuantity = setQuantity.bind(val);
		val.toggleOption = toggleOption.bind(val);
		val.totalCall = totalCall.bind(val);
		val.changeOptionCount = changeOptionCount.bind(val);
		val.validateProduct = validateProduct.bind(val);
		val.flashWarning = this.settings.flashWarning.bind(this.settings)
		val.modifier_groups?.map(r => {
			["hidden", "multiple_max", "multi", "locked", "quantity_ability", "max_quantity", "min_quantity", "required"].map(k => r[k] = +r[k]);
			if (r.type === 'pizza') {
				r.modifiers.forEach(m => m.switchToppingSize = switchToppingSize.bind(m))
				let pizzasOptions = r.subgroups.map(po => {
					po.switchToppingSize = switchToppingSize.bind(po)
					if (!po.modifier_id && po.modifiers.length) {
						let activeSize;
						po.modifiers.find(m => {
							if (+m.default_value) {
								activeSize = m;
								return true
							}
							if (m.key === 'WH') {
								activeSize = m;
							}
						})
						po.switchToppingSize(activeSize);
						return po;
					} else {
						return po
					}
				}).filter(po => !r.modifiers.find(m => po.id === m.id)) // Remove that alread in modifiers
				r.modifiers = [...r.modifiers, ...pizzasOptions];
			}
			r.modifiers.map(opt => {
				opt.addon_title = r.modifier_group_title;
				opt.addon_id = r.modifier_group_id;
				opt.total_index = total_index.current++;
				if (r.quantity_ability == 1) {
					opt.quantity = opt.quantity ? opt.quantity : 0;
				}
				if (+opt.default_value && !val.activeModifiers[opt.modifier_id]) {
					val.toggleOption(opt, r);
				}
			})
		});
		return val;
	}

}

//##############################################
//Helpers Single
///#####################################################

const totalPrice = function () {
	return this.isCombo ? this.totalComboPrice() : this.totalProductPrice()
}

const totalProductPrice = function () {
	let priceOfProduct = parseFloat(this.price);
	if (this.activeModifiers) {
		for (let [key, value] of Object.entries(this.activeModifiers)) {
			priceOfProduct += parseFloat(value['modifier_unit_price']) * parseFloat(value['quantity'] ?? 1);
		}
	}
	return (this.quantity * priceOfProduct).toFixed(2);
}

const totalComboPrice = function () {
	let priceOfProduct = parseFloat(this.combo_meal_price);
	priceOfProduct += +this.main_menu.totalProductPrice();
	this.option_groups.forEach(g => {
		g.items.forEach(item => {
			if (g.active_item === item.menu_item.menu_id) {
				priceOfProduct += +item.menu_item.totalProductPrice()
			}
		})
	})
	return (this.quantity * priceOfProduct).toFixed(2);
}

const totalCall = function () {
	if (this.nutritional_facts !== null) {
		return this.nutritional_facts.total_calories * this.quantity
	}

}
const changeQuantity = function (q) {
	if (this.quantity + q >= this.quantityMin) {
		this.quantity += q;
		return true;
	}
	return false;

}
const setQuantity = function (e) {
	const q = parseInt(e.target.value);
	if (q >= this.quantityMin) {
		this.quantity = q;
		return true;
	}
	return false;
}
const toggleOption = function (option, addon) {
	if (addon.quantity_ability) {
		return false;
	}
	if (this.activeModifiers[option.modifier_id]) {
		delete this.activeModifiers[option.modifier_id]
	} else {

		let totalSelected = 0;

		Object.keys(this.activeModifiers).map(key => {
			const value = this.activeModifiers[key]
			if (value.addon_id == addon.modifier_group_id) {
				if (addon.multiple_max == 1) {
					delete this.activeModifiers[value.modifier_id];
				} else {
					totalSelected++
				}
			}
		});

		if (addon.multiple_max == totalSelected) {
			this.flashWarning("menu.reached_modifer_limit", true)
		} else {
			option.addon_title = addon.modifier_group_title;
			option.hidden = addon.hidden;
			this.activeModifiers[option.modifier_id] = option
		}
	}
}


const changeOptionCount = function (val, option, addon) {

	let totalSelected = 0;
	let sum = 0;

	val = +val;
	option.quantity = +option.quantity + val;

	addon.modifiers.map(opt => {
		sum += opt.quantity
		if (+opt.quantity) {
			totalSelected++
		}
	});
	if (option.quantity < 0) {
		option.quantity = 0;
		return false;
	}

	if (option.quantity === 0) {
		Object.keys(this.activeModifiers).map(key => {
			if (this.activeModifiers[key].addon_id === addon.modifier_group_id) {
				delete this.activeModifiers[key]
			}
		});
	}

	if (addon.multiple_max < totalSelected) {
		option.quantity = option.quantity - val;
		this.flashWarning("menu.reached_modifer_limit", true);
		return false;
	}
	if (sum > addon.max_quantity) {
		option.quantity = option.quantity - val;
		this.flashWarning("menu.reached_modifer_limit_quantity", true)
		return false;
	}

	if (addon.min_quantity <= sum) {
		addon.modifiers.filter(opt => opt.quantity > 0).map(opt => {
			this.activeModifiers[opt.modifier_id] = opt;
		});
	} else if (addon.min_quantity > sum && this.activeModifiers[option.modifier_id]) {
		Object.keys(this.activeModifiers).map(key => {
			if (this.activeModifiers[key].addon_id === addon.modifier_group_id) {
				delete this.activeModifiers[key]
			}
		});
	}
}

const validateProduct = function () {
	const required = this.modifier_groups.filter(el => el.required == 1);
	let error = false;
	required.map(el => {
		let totalSelected = 0;
		let sum = 0;
		Object.keys(this.activeModifiers).map(key => {
			if (el.modifier_group_id == this.activeModifiers[key].addon_id) {
				totalSelected++;
				sum += this.activeModifiers[key].quantity
			}
		});
		if (!totalSelected || sum < el.min_quantity) {
			this.showInRed.push(el.modifier_group_id);
			error = true;
		}
	});

	if (error) {
		return false;
	}

	return true;
}

const validateComboProduct = function () {
	if (!this.main_menu.validateProduct()) {
		return false;
	}
	let valid = true;
	this.option_groups.forEach(group => {
		group.items.forEach((item) => {
			if (group.active_item === item.menu_item.menu_id && !item.menu_item.validateProduct()) {
				valid = false
			}
		});
	})
	return valid
}


function generateComboIngredients() {
	let arr = {}

	for (let [key, value] of Object.entries(this.main_menu.activeModifiers)) {
		if (value['hidden'] !== 1) {
			arr[key] = value;
		}
	}
	this.option_groups.forEach((og, index) => {
		let activeItem = og.items.find(item => item.menu_item.menu_id === og.active_item)
		for (let [key, value] of Object.entries(activeItem.menu_item.activeModifiers)) {
			if (value['hidden'] !== 1) {
				arr[index + '_' + key] = value
			}
		}
	})
	return arr;
}

function switchToppingSize(size) {
	this.default_value = size.default_value
	this.modifier_id = size.modifier_id
	this.modifier_unit_price = size.modifier_unit_price
	this.key = size.key
	this.modifier_title = this.title
	this.addon_type = 'pizza'
}

//##############################################
//MenuList
//Find Product In Array By ID
const findCategoryById = function (id, cat) {
	if (!id) return false;
	if (!cat) cat = this;

	for (let i = 0; i < cat.length; i++) {
		const el = cat[i];
		if (el.id == id) {
			return el
		}
		if (el.subcategories.length) {
			let found = false;
			found = this.findCategoryById(id, el.subcategories);
			if (found) return found;
		}
	}
	return false;
}

//#####################################################
const findProductsByName = function (query, cat) {
	if (!query) return false;
	if (!cat) cat = this;
	let arr = [];
	for (let i = 0; i < cat.length; i++) {
		const el = cat[i];
		for (let x = 0; x < el.menu_items.length; x++) {
			const product = el.menu_items[x];
			let searchArr = query.split(" ");
			let foundAll = true;
			for (let j = 0; j < searchArr.length; j++) {
				if (product.menu_title.toLowerCase().indexOf(searchArr[j].toLowerCase()) === -1) {
					foundAll = false;
					break;
				}
			}
			if (foundAll) arr.push(product);
		}

		if (el.subcategories.length) {
			arr = arr.concat(this.findProductsByName(query, el.subcategories));
		}
	}
	return arr;
};
