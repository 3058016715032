import {BaseStore} from "./base-store.service";
import {Injectable} from "@angular/core";

@Injectable({
	providedIn: 'root'
})
export class PromtStore extends BaseStore {

	private settings = {
		content: "",
		buttonConfirm: "",
		buttonCancel: "",
		deny: null,
		success: null
	}

	getSettings(){
		return this.settings;
	}

	constructor() {
		super();
	}

	private setPrompt(val){
		this.settings = val;
	}

	show(val){
		this.commit('setPrompt', val);
	}
}
