import {Component, OnInit} from '@angular/core';
import {SettingsStore} from "../../store/settings.service";
import {Subscription} from "rxjs";

@Component({
	selector: 'app-alert',
	templateUrl: './alert.component.html'
})
export class AlertComponent implements OnInit {

	constructor(public settings: SettingsStore) {
	}

	ngOnInit(): void {
		this.settings.getStream$().subscribe(data=>{
			if (data.name === "setAlert" && data.data){
				setTimeout(()=> {
					this.clearWarning();
				}, 10000)
			}
		})
	}

	clearWarning() {
		this.settings.commit("setAlert", "");
	}

}
