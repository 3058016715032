import {Injectable} from '@angular/core';
import {HttpService} from "../services/http.service";
import {SettingsStore} from "./settings.service";
import {forkJoin} from "rxjs";
import {AuthStore} from "./auth.service";
import {BaseStore} from "./base-store.service";

@Injectable({
	providedIn: 'root'
})
export class RewardsStore extends BaseStore {

	private rewards: any;

	constructor(private http: HttpService, private settings: SettingsStore, private auth: AuthStore) {
		super();
	}

	getRewards() {
		return this.rewards;
	}

	//Mutations
	private setRewards(val) {
		this.rewards = val;
	}

	loadLoyaltyProgram(callback){
		this.http.get(`/groups/${this.settings.getGroupId()}/loyalty_program`).subscribe(data=>callback?.(data))
	}

	//Actions
	loadRewards(callback: Function) {
		const memberId = this.auth.getUser()?.member_id;
		let loyaltyProgram = this.http.get(`/groups/${this.settings.getGroupId()}/loyalty_program`);
		let loyaltyProfile = this.http.get(`/users/${memberId}/loyalty/profile`)
		let loyaltyHistory = this.http.get(`/users/${memberId}/loyalty/history`)
		forkJoin([loyaltyProgram, loyaltyProfile, loyaltyHistory]).subscribe(results => {
			let rewards = {...results[0],...results[1]};

			//Available points history filter only points
			rewards.history = results[2]?.filter(o => {
				if (!o.object_type.endsWith("_money")) {
					o.points = (+o.points >= 0) ? `+${o.points}` : `${o.points}`
					return true;
				} else {
					return false
				}
			});

			//Next Level & current level
			rewards.status_levels = rewards.status_levels.reverse();
			let currentIndex = rewards.status_levels.findIndex(el=>el.id === rewards.status_id)
			rewards.currentLevel = rewards.status_levels[currentIndex];
			rewards.nextLevel = rewards.status_levels[currentIndex + 1];
			if (rewards.nextLevel){
				rewards.currentLevel.percentage = ((rewards.total_points - rewards.currentLevel.min_value) * 100) / (rewards.currentLevel.max_value - rewards.currentLevel.min_value)
				//rewards.currentLevel.percentage = rewards.total_points / rewards.currentLevel.max_value * 100
			}

			//Calc point ratio
			rewards.offers.find(el=>{
				if (el.discount_type === "Exact amount" && el.purchase_amount){
					rewards.ratio = el.purchase_amount/el.discount_value;
					rewards.total_available_amount = (rewards.available_points/rewards.ratio).toFixed(2);
					return true;
				}
				return false;
			})

			rewards.offers = rewards.offers.filter(o=>o.redeemable)

			this.commit("setRewards", rewards);
			callback?.(rewards)
		});
	}

	loadBusinessRewards({bId, callback}){
		const memberId = this.auth.getUser()?.member_id;
		this.http.get(`/users/${memberId}/profile/offers?online_ordering=1&business_id=${bId}`).subscribe(data=>{
			callback?.(data);
		})
	}

	loadUserLoyaltyProfile(callback){
		const memberId = this.auth.getUser()?.member_id;
		this.http.get(`/users/${memberId}/loyalty/profile`).subscribe(data=>{
			callback?.(data);
		})
	}
}
