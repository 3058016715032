import {Injectable} from '@angular/core';
import {SettingsStore} from "./settings.service";
import {AuthStore} from "./auth.service";
import {TranslateService} from "@ngx-translate/core";
import {PromtStore} from "./promt.service";
import {CheckoutPromptService} from "./checkout-prompt.service"
import {LocationStore} from "./location.service";
import {GiftPromtStore} from "./gift-promt.service";
import {CartStore} from "./cart.service";
import {GiftCardsStore} from "./gift-cards.service";
import {RewardsStore} from "./rewards.service";
import {MenuStore} from "./menu.service";

@Injectable({
	providedIn: 'root'
})
export class Store {

	constructor(
		public settings: SettingsStore,
		public promt: PromtStore,
		public gc: GiftCardsStore,
		public giftPromt: GiftPromtStore,
		public checkoutPrompt: CheckoutPromptService,
		public auth: AuthStore,
		public menu: MenuStore,
		public cart: CartStore,
		public location: LocationStore,
		public rewards: RewardsStore,
		public translate: TranslateService,
	) {

	}
}
