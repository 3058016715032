<div class="container mb-4" *ngIf="business">
	<h3 class="mb-md-4 md-5 mb-2">
		{{(favorite ? "home.fav_location" : "Nearest Location") | translate}}
	</h3>
	<div class="container930">
		<div class="border rounded-lg py-3 px-2 text-center" mat-ripple routerLink="/locations/all">
			<div class="mb-2">
				<div class="svgIcons location"></div>
			</div>
			<div class="d-flex justify-content-center">
				<div>
					<h3 class="mb-2">{{business.name}}</h3>
					<h5 class="">{{business.address.address1}}<span *ngIf="business.address.address2"> {{business.address2}}</span>, {{business.address.city}}, {{business.address.state}}</h5>
					<div class="justify-content-center captText text-nowrap pr-1">
						<span *ngIf="business.distance">{{business.distance}} {{"home.miles_away" | translate}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
