import {Component, Input, OnInit} from '@angular/core';
import {Store} from "../../../store/store.service";
import {Subscription} from "rxjs";

@Component({
	selector: 'app-favourite-location',
	templateUrl: './favourite-location.component.html'
})
export class FavouriteLocationComponent implements OnInit {

	public business: any;
	private position= this.store.location.getGeoPosition();
	private sub: Subscription
	@Input() favorite:any;

	constructor(private store: Store) {
	}

	ngOnInit(): void {
		this.initBusinesses()
		this.sub = this.store.location.getStream$().subscribe(data=>{
			if (data.name === "setGeoPosition"){
				this.position = this.store.location.getGeoPosition();
				this.initBusinesses()
			}
		})
	}

	initBusinesses(){
		if (this.favorite){
			this.business = this.store.location.getBusinessByID(this.favorite.id);
		}else if (this.position.lat && !this.position.blocked){
			this.business = this.store.location.getBusinesses()?.[0];
		}else{
			this.business = null;
		}
	}

}
