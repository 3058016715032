import {Injectable} from '@angular/core';

declare var window;

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {

	constructor() {
	}

	setItem(name: string, value: any, json: boolean = true) {
		if (!value){
			return this.removeItem(name)
		}else{
			return window.localStorage.setItem(name, json ? JSON.stringify(value) : value);
		}
	}

	getItem(name: string, json: boolean = true) {
		let value = window.localStorage.getItem(name);
		return json ? JSON.parse(value) : value;
	}

	removeItem(name: string) {
		return window.localStorage.removeItem(name);
	}
}
