import { Component, Input, OnInit } from '@angular/core';
import { Store } from 'src/app/store/store.service';

@Component({
	selector: 'app-delete-account-prompt',
	templateUrl: './delete-account-prompt.component.html',
})
export class DeleteAccountPromptComponent implements OnInit {
	displayDeletePrompt = true;
	inputValue = ""
	constructor(public store: Store) {}

	ngOnInit(): void {}
	closePrompt() {
		this.store.auth.closeDeleteUserPrompt();
	}
	onKey(value) {
		this.inputValue = value;
		if(this.inputValue === this.store.translate.instant("account.delete_account_check_string")){
			this.displayDeletePrompt = false
		}else{
			this.displayDeletePrompt = true
		}
	}
	deleteUser() {
		if(this.inputValue === this.store.translate.instant("account.delete_account_check_string")){
			this.store.auth.deleteUserAccount((data) => {
				this.store.auth.signOut();
				this.store.settings.flashWarning(this.store.translate.instant("account.successfully_deleted"), true);
			});
		}
	}
}
