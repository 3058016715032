import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from "@angular/common/http";
import duuidjs from "device-uuid"


declare var window;

@Injectable({
	providedIn: 'root'
})
export class HttpService {
	private baseUrl = window.asoc.baseUrl;
	private staticHeaders:any;
	private defaultParams:any = {}

	constructor(private http: HttpClient) {

		if (window.cordova){
			this.staticHeaders = {
				'Device-Id': window.device?.uuid || "unknown",
				'Device-Platform': window.device?.platform || "unknown"
			}
		}else {
			const device = duuidjs?.DeviceUUID()
			this.staticHeaders =  {
				'Device-Id': device?.get() || "unknown" ,
				'Device-Platform': device.parse()?.platform || "unknown"
			}
		}
		if (window.asoc['Api-Key']){
			this.staticHeaders['Api-Key']= window.asoc['Api-Key'];
		}
		this.defaultParams.headers = this.staticHeaders
	}

	setParams(params: any) {
		this.defaultParams = {headers:{}, ...params};
		this.defaultParams.headers = {...this.defaultParams.headers, ...this.staticHeaders}
	}

	private getUrl(url: string = ''): string {
		return this.baseUrl + url;
	}

	private checkHttpParams(params: any){
		if (params?.skipLoader){
			let httpParams = new HttpParams();
			params.params = httpParams.append('skipLoader', 'true');
		}
		return params;
	}

	public get(url: string = '', params: any = {}): Observable<any> {
		params = this.checkHttpParams(params);
		return this.http.get(this.getUrl(url), {...this.defaultParams, ...params});
	}

	public post(url: string = '', data: any = {}, params: any = {}): Observable<any> {
		params = this.checkHttpParams(params);
		return this.http.post(this.getUrl(url), data,  {...this.defaultParams, ...params});
	}

	public put(url: string = '', data: any = {}, params: any = {}): Observable<any> {
		params = this.checkHttpParams(params);
		return this.http.put(this.getUrl(url), data, {...this.defaultParams, ...params});
	}

	public delete(url: string = '', data: any = {}, params: any = {}): Observable<any> {
		params = this.checkHttpParams(params);
		return this.http.delete(this.getUrl(url), {...this.defaultParams, ...params});
	}
}
