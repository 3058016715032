import { Component, Input, OnInit } from '@angular/core';
import { Store } from '../../store/store.service';

@Component({
	selector: 'app-gift-amount',
	templateUrl: './gift-amount.component.html',
})
export class GiftAmountComponent implements OnInit {
	@Input() gcard: any;
	amounts = [10, 25, 50, 100];
	cart = this.store.cart.getCartObject();
	custom_amount: any = '0.00';

	constructor(private store: Store) {}

	ngOnInit(): void {}

	getAmount(e) {
		this.store.cart.setGCAmount(e.value);
		setTimeout(() => {
			this.store.cart.commit('updateCartDate');
		});
	}

	changeCustomAmount(e) {
		const el = e.currentTarget;
		if (el.value) {
			this.custom_amount = el.value;
			this.custom_amount = this.custom_amount ? this.custom_amount : 0;
		} else {
			this.custom_amount;
		}

		this.gcard.custom_ammount = parseFloat(this.custom_amount).toFixed(2);
		this.store.cart.setGCAmount(this.gcard.custom_ammount);
		setTimeout(() => {
			this.store.cart.commit('updateCartDate');
		});
	}

	onFocus(e) {
		if (e.currentTarget.value === '0.00') {
			e.currentTarget.value = '';
		}
	}

	customTipDeselect(e) {
		const el = e.currentTarget;
		if (el.value) {
			el.value = parseFloat(el.value).toFixed(2);
			this.gcard.custom_ammount = parseFloat(el.value).toFixed(2);
			this.store.cart.setGCAmount(this.gcard.custom_ammount);
			setTimeout(() => {
				this.store.cart.commit('updateCartDate');
			});
		}
	}
}
