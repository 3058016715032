<div class="giftCardPaymentSection">

	<hr *ngIf="title && !hideHR">
	<h3 *ngIf="title" class="py-2">{{title}}</h3>

	<div class="giftCardsList" *ngIf="!hideList && giftCards?.length">
		<div
			class="card p-2"

			*ngFor="let g of giftCards; let i = index"
			(click)="enable_cards_selections ? selectCard(g) : showCardInfo(g)"
			[class]="{active: this.cart.giftCards[g.card_id]}"
		>
			<h3 class="d-flex justify-content-between">
				{{g.title}}
				<!-- {{ "payment.balance" | translate }}:
				{{g?.currency?.symbol}}{{g.balance}} -->
				<div class="closeIcon svgIcons close_rounded ml-2" (click)="removeCard($event,i)"></div>
			</h3>

			<div class="h4 mt-2 mb-2">
				<!-- {{user?.first_name}} {{user?.last_name}} -->
				{{g.card_number.split('+')[0]}}
			</div>
			<div class="h4 mt-2">{{ "payment.balance" | translate }}:{{g?.currency?.symbol}}{{g.balance}}</div>
		</div>
		<div class="pl-3"></div>
	</div>

	<a
		[routerLink]="['/add-gift-card']"
		class="regText d-flex align-items-center mb-3"
		*ngIf="!hideNavs"
	>
		<div class="svgIcons rounded_plus mr-2"></div>
		<div>{{ "payment.add_new_gc_link" | translate }}</div>
	</a>

	<a
		[routerLink]="['/buy-ewallet']"
		class="regText d-flex align-items-center mb-3"
		*ngIf="!hideNavs"
	>
		<div class="svgIcons rounded_plus mr-2"></div>
		<div>{{ "payment.buy_ewallet_link" | translate }}</div>
	</a>

	<!-- <div class="giftSection text-center py-5 mb-4" *ngIf="!hideCheckoutSection && gcTotal">
		<div class="position-relative">
			<h4>{{'payment.gift_card_balance' | translate}} {{giftCards[0]?.currency?.symbol}}{{ isCardsSelected() ? selectedTotal() : gcTotal}}!</h4>
			<div class="mt-2">
				<mat-checkbox
					(click)="toggleGCards($event)"
					[checked]="isCardsSelected()">
					<span class="regText">{{'payment.apply_gc_balance' | translate}} </span>
				</mat-checkbox>
			</div>
		</div>
	</div> -->
</div>
