import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from "../../store/store.service";

@Component({
	selector: 'app-welcome',
	templateUrl: './welcome.component.html'
})
export class WelcomeComponent implements OnInit, OnDestroy {

	settings: any;

	constructor(private store: Store) {
		this.settings = this.store.settings.getSettings();
		this.store.settings.topBar({active: false});
		this.store.settings.commit("setShowBottomBar", false);
	}

	ngOnInit(): void {
	}

	ngOnDestroy(): void {
		this.store.settings.commit("setShowBottomBar", true);
	}

}
