import { TypeScriptEmitter } from '@angular/compiler';
import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";

declare var window;

@Component({
	selector: 'app-specials',
	templateUrl: './specials.component.html'
})
export class SpecialsComponent implements OnInit {

	@Input() list:any;

	slideConfig = {
		slidesToShow: 1.2,
		infinite: false,
		slidesToScroll: 1,
		// arrows: true,
		dots: true,
		swipeToSlide: true,
		autoplay: true,
		autoplaySpeed: 4000,
		pauseOnFocus: true,
		pauseOnHover: true,
		pauseOnDotsHover: true,
		speed: 700,
		// fade: true,
		// cssEase: 'linear',
		// centerMode: true,
	}
	isSliding:boolean = false;

	constructor(private router: Router) {
	}

	ngOnInit(): void {
	}

	validURL(str:string) {
		let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
		return !!pattern.test(str);
	}

	beforeChange(e){
		this.isSliding = true;
	}

	afterChange(e){
		this.isSliding = false;
	}

	goToURL(url:string){
		if (this.isSliding) { return false; }
		if (this.validURL(url)) {
			if(window.cordova && window.cordova.InAppBrowser) {
				window.cordova.InAppBrowser.open(
					url,
					'_blank',
					'toolbartranslucent=no'
				);
			} else {
				window.open(url, "_system")
			}		
		} else {
			this.router.navigate([url]);
		}
	}

}
