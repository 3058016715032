import {Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'app-offers-list',
	templateUrl: './offers-list.component.html'
})
export class OffersListComponent implements OnInit {

	@Input() offers: any;
	@Input() dummy: boolean;

	constructor() {
	}

	ngOnInit(): void {
	}

}
