import {Component, OnInit} from '@angular/core';
import {Store} from "../../store/store.service";
import {Router} from "@angular/router";

@Component({
	selector: 'app-location-search-box',
	templateUrl: './location-search-box.component.html'
})
export class LocationSearchBoxComponent implements OnInit {

	cart = this.store.cart.getCartObject();

	constructor(private store: Store, private router: Router) {
	}

	ngOnInit(): void {
	}

	onAutocompleteSelected(e) {
		if (this.cart.orderFamily == "delivery") {
			const address = this.store.location.generateAddress(e);
			this.store.location.commit("setTempAddress", address);
			this.router.navigate(["/add-address"]);
		} else {
			if (e.name != "") {
				const position = {
					lat: e.geometry.location.lat(),
					lng: e.geometry.location.lng(),
				}
				this.store.location.commit("setGeoPosition", position);
			}
		}
	}

}
