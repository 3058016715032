import { BaseStore } from './base-store.service';
import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
import { SettingsStore } from './settings.service';

@Injectable({
	providedIn: 'root',
})
export class GiftCardsStore extends BaseStore {
	private giftCardData: any;
	private allGiftCards: any;
	private allEwalletCards: any;

	getGiftCardData() {
		return this.giftCardData;
	}

	getAllGiftCards() {
		return this.allGiftCards;
	}

	getEwalletCards() {
		return this.allEwalletCards;
	}

	constructor(private http: HttpService, private settings: SettingsStore) {
		super();
		this.resetGCData();
	}

	resetGCData() {
		this.giftCardData = {
			purchaser: {
				user_id: null,
				guest_id: null,
			},
			payment: {
				card_number: null,
				transaction_id: null,
				credit_card_id: null,
			},
			cards: [],
			contact_info: {
				email: null,
				first_name: null,
				last_name: null,
				name: null,
				phone: null,
				saved: false,
			},
		};
	}

	loadAllGiftCards(callback) {
		let url = `/groups/${this.settings.getGroupId()}/gift_card_types`;
		this.http.get(url).subscribe((data) => {
			if (!Array.isArray(data)) {
				data = [data];
			}
			this.allGiftCards = data.filter((el) => el.egift_enabled);
			callback?.(this.allGiftCards);
		});
	}

	loadEwalletCards(callback) {
		let url = `/groups/${this.settings.getGroupId()}/gift_card_types`;
		this.http.get(url).subscribe((data) => {
			if (!Array.isArray(data)) {
				data = [data];
			}
			this.allEwalletCards = data.filter((el) => el.ewallet_enabled);
			callback?.(this.allEwalletCards);
		});
	}

	buyGiftCards({ data, callback }: any = {}) {
		data = data ?? this.giftCardData;
		this.http
			.post(
				`/groups/${this.settings.getGroupId()}/gift_cards/egift`,
				data,
				{ observe: 'response' }
			)
			.subscribe(
				(res) => {
					callback?.();
					this.resetGCData();
				},
				(err) => this.settings.flashWarning(err.error)
			);
	}

	buyEwallet({ data, callback }: any = {}) {
		this.http
			.post(`/users/${data.member_id}/gift_cards`, data, {
				observe: 'response',
			})
			.subscribe(
				(res) => {
					callback?.(res);
					this.resetGCData();
				},
				(err) => this.settings.flashWarning(err.error)
			);
	}

	refillGiftCard({ data, callback }) {
		this.http
			.put(
				`/users/${data?.member_id}/gift_cards/${data?.gift_card_id}`,
				data,
				{ observe: 'response' }
			)
			.subscribe(
				(res) => callback?.(res),
				(err) => callback?.(err)
			);
	}

	linkGiftToUser({ data, callback, user_id }) {
		this.http
			.get(
				`/groups/${this.settings.getGroupId()}/gift_cards?card_number=${
					data.gift_card_number
				}&cvv=${data.cvv}`,
				{ observe: 'response' }
			)
			.subscribe(
				(res) => {
					if (user_id) {
						this.http
							.put(
								`/groups/${this.settings.getGroupId()}/gift_cards/${
									res.body.card_id
								}`,
								{ user_id },
								{ observe: 'response' }
							)
							.subscribe(
								(res) => callback?.(res),
								(err) => callback?.(err)
							);
					} else {
						return callback?.(res);
					}
				},
				(err) => callback?.(err)
			);
	}

	deleteGiftCard({ id, callback, user_id }: any) {
		this.http
			.delete(`/users/${user_id}/gift_cards/${id}`)
			.subscribe(() => callback?.());
	}

	loadUserGiftCards({ user_id, callback }: any) {
		this.http
			.get(`/users/${user_id}/gift_cards`)
			.subscribe((data) => callback?.(data));
	}

	loadGCAutoRecharhSettings({ user_id, card_id, callback }: any) {
		
		this.http
			.get(
				`/users/${user_id}/gift_cards/${card_id}/auto_recharge_settings`
			)
			.subscribe((data) => callback?.(data));
	}

	saveAutoReload({ user_id, card_id, data, callback }: any) {
		this.http
			.put(
				`/users/${user_id}/gift_cards/${card_id}/auto_recharge_settings`,
				data,
				{ observe: 'response' }
			)
			.subscribe(
				(res) => callback?.(res.body),
				(err) => this.settings.flashWarning(err.error)
			);
	}
}
