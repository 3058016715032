<div id="giftPromt" *ngIf="show">
	<div class="container min-h-100 d-flex flex-column">
		<div class="text-right">
			<div class="closeIcon svgIcons close_rounded cursorPointer" (click)="close()"></div>
		</div>
		<div>
			<!-- <div class="svgIcons giftPromt"></div> -->
			<h1 class="d-flex justify-content-between my-2">
				<span>Gift Card</span>
				<span class="color2">{{cart.business?.currency?.symbol}}{{card?.balance}}</span>
			</h1>

			<h5 class="color2 text-center my-4">{{"rewards.use_in_store" | translate}}</h5>
			<div class="text-center">
				<svg class="barcode w-100" [attr.jsbarcode-value]="card.card_number.split('+')[0]"></svg>
			</div>
		</div>

		<div *ngIf="card.allow_online_purchase" class="mt-auto">
			<div class="pt-4">
				<a [routerLink]="'reload-gift-card/' + card.card_id" (click)="close()" mat-raised-button>{{"gc.reload" | translate}}</a>
			</div>
		</div>

		<div *ngIf="this.card.allow_auto_recharge">
			<div class="pt-4">
				<a [routerLink]="'auto-reload-settings-gift-card/' + card.card_id" (click)="close()" mat-raised-button>{{"gc.auto_reload_settings" | translate}}</a>
			</div>
		</div>
		<div class="py-3"></div>
	</div>


</div>
