<div id="app">

	<app-promt></app-promt>
	<app-gift-promt></app-gift-promt>

	<app-nav></app-nav>
	<div id="mainContainer" [class]="{bottomBar:showBottomBar}">
		<app-alert></app-alert>
		<app-top-bar></app-top-bar>
		<router-outlet></router-outlet>
		<app-bottom-bar></app-bottom-bar>
	</div>
	<app-loader></app-loader>
</div>
