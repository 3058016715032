<div id="bottomBar">
	<div class="box">
		<div class="container d-flex justify-content-around align-items-center color8 h-100">
			<a routerLink="/home">
				<span class="svgIcons bottomBarIcon bottomBarHome"></span>
				{{'bottom_bar.home' | translate}}
			</a>
			<a routerLink="/locations/all">
				<span class="svgIcons bottomBarIcon bottomBarLocation"></span>
				{{'bottom_bar.location' | translate}}
			</a>
			<a routerLink="/profile">
				<span class="svgIcons bottomBarIcon bottomBarProfile"></span>
				{{'bottom_bar.profile' | translate}}
			</a>
			<a *ngIf="settings.show_previous_order" routerLink="/orders">
				<span class="svgIcons bottomBarIcon bottomBarMenu">
				</span>
				Orders
			<div *ngIf="activeOrderCount > 0" class="quantity ml-4">
				<span>{{  activeOrderCount }}</span>
			</div>
			</a>
		</div>
	</div>
</div>
