import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseStore } from './base-store.service';
import { HttpService } from '../services/http.service';
import { LocalStorageService } from '../services/local-storage.service';
import { SettingsStore } from './settings.service';
import { TranslateService } from '@ngx-translate/core';
import { CartStore } from './cart.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Order } from './models/order';

@Injectable({
	providedIn: 'root',
})
export class AuthStore extends BaseStore {
	private user = null;
	private signupFields = null;
	private addresses = [];
	private favouriteBusinesses = {};
	private sessionToken = null;
	deleteAccountPrompt = false
	private deleteAccountPromptSource = new BehaviorSubject<String>("Close");
	deleteAccountPromptChange = this.deleteAccountPromptSource.asObservable();
	constructor(
		private router: Router,
		private http: HttpService,
		private storage: LocalStorageService,
		private translate: TranslateService,
		private settings: SettingsStore,
		private cart: CartStore
	) {
		super();
	}

	//########################################################
	//Getters

	getDeleteUserPromptStatus(){
		return this.deleteAccountPromptSource
	}

	closeDeleteUserPrompt(){
		this.deleteAccountPromptSource.next("Close");
	}

	openDeleteUserPrompt(){
		this.deleteAccountPromptSource.next("Open");
	}
	getUser() {
		return this.user;
	}

	getSignupFields() {
		return this.signupFields;
	}

	getFavouriteBusinesses() {
		return this.favouriteBusinesses;
	}

	isLoggedIn() {
		return this.user?.member_id;
	}

	getAddresses() {
		return this.addresses;
	}

	//########################################################
	//Mutations

	private setUser(val) {
		if (val) {
			if (val.password) {
				delete val.password;
			}
			val.member_id = val.id;
		} else {
			this.http.setParams({});
		}
		this.user = val;

		this.storage.setItem('user', val);
		this.loadLocale();
	}

	private setSignupFields(val) {
		this.signupFields = val;
	}

	private setAddresses(val) {
		this.addresses = val;
	}

	//###########################################################
	//Methods(actions)

	loadSessionToken(callback: any = null) {
		this.http
			.post(`/users/${this.user?.member_id}/session_token/generate`,{'type': 'user', 'id': this.user?.member_id}, {
				observe: 'response',
			})
			.subscribe((res) => callback?.(res));
	}

	loadSavedUser() {
		//Get user from local storage
		const user = this.storage.getItem('user');
		if (user?.member_id) {
			this.http.setParams({ headers: { 'Auth-Token': user.auth_token } });
			this.commit('setUser', user);
		}
	}

	loginIn(data: any, callback: any = null) {
		const id = this.settings.getGroupId();
		this.http
			.post(`/groups/${id}/auth_tokens`, data, { observe: 'response' })
			.subscribe(
				(res) => {
					if (res.status === 200) {
						this.http.setParams({
							headers: { 'Auth-Token': res.body.auth_token },
						});
						this.settings.loadSettings((data) => {
							if (data.fields) {
								data.fields.auth_token = res.body.auth_token;
								this.commit('setUser', data.fields);
							}
							callback?.(data);
						});
					}
				},
				(error) => {
					this.settings.flashWarning(error.error);
				}
			);
	}

	loadSignupFields(callback: any = null) {
		const id = this.settings.getGroupId();
		this.http.get(`/groups/${id}/settings/signup`).subscribe((data) => {
			this.commit('setSignupFields', data);
			data = this.modifyFields(data);
			callback?.(data);
		});
	}

	signup({ data, callback }: any = {}) {
		const id = this.settings.getGroupId();
		this.http
			.post(`/groups/${id}/members`, data, { observe: 'response' })
			.subscribe(
				(res) => {
					if (res.status === 200) {
						this.http.setParams({
							headers: {
								'Auth-Token': res.body.user_info?.auth_token,
							},
						});
						this.settings.loadSettings((data) => {
							if (res.body.user_info.locale) {
								delete res.body.user_info.locale;
							}
							data.fields = {
								...data.fields,
								...res.body.user_info,
							};
							data.fields && this.commit('setUser', data.fields);
							callback?.(data);
						});
					}
				},
				(res) => {
					if (res.error.errors) {
						let message = '';
						Object.keys(res.error.errors).map((key) => {
							message += res.error.errors[key] + '<br>';
						});
						this.settings.flashWarning(message.slice(0, -4));
					}
				}
			);
	}

	resetPassword(data, callback: any = null) {
		this.http.post(`/users/reset`, data, { observe: 'response' }).subscribe(
			(res) => {
				if (res.status === 200) {
					//TODO: finish registration
					callback?.(res);
				}
			},
			(res) => {
				this.settings.flashWarning(res.error);
			}
		);
	}

	loadProfileFields(callback: any = null) {
		const id = this.settings.getGroupId();
		this.http
			.get(`/groups/${id}/users/${this.user?.member_id}/profile`)
			.subscribe((data) => {
				data = this.modifyFields(data, false);
				callback?.(data);
			});
	}

	updateProfile(data: any, callback: any = null) {
		const id = this.settings.getGroupId();
		this.http
			.put(`/groups/${id}/users/${this.user?.member_id}/profile`, data, {
				observe: 'response',
			})
			.subscribe(
				(res) => {
					if (res.status === 200) {
						this.commit('setUser', { ...this.user, ...data });
						callback?.(res);
					}
				},
				(res) => {
					this.settings.flashWarning(res.error.message);
				}
			);
	}

	loadNotificationsSettings(callback) {
		this.http
			.get(
				`/auth/users/${this.user.member_id}/profile/notification_settings`
			)
			.subscribe((data) => callback?.(data));
	}
	deleteUserAccount(callback){
		this.http
			.delete(
				`/users/${this.user.member_id}/profile`
			)
			.subscribe((data) => callback?.(data));
	}

	saveNotificationsSettings({ data, callback }: any = {}) {
		this.http
			.put(
				`/auth/users/${this.user.member_id}/profile/notification_settings`,
				data
			)
			.subscribe((res) => callback?.(res));
	}

	loadOrderHistory(): Observable<Order[]> {
		return this.http.get(
			`/businesses/users/${this.user.member_id}/orders?page=1&per_page=20`
		);
	}

	loadOrderDetails(id: string): Observable<Order> {
		return this.http.get(
			`/businesses/users/${this.user.member_id}/orders/${id}`
		);
	}

	reOrder(id, callback: any = null) {
		this.http
			.get(
				`/businesses/users/${this.user.member_id}/orders/${id}?validate=1`
			)
			.subscribe((data) => {
				this.cart.generateCartFromData(data);
				this.router.navigate(['/cart']);
				//callback?.(data)
			});
	}

	//helper
	//update profile fields
	modifyFields(data, passwordRequired = true) {
		data.fields.password = {
			field_id: 'password',
			required: passwordRequired,
			sort_order: '10000',
			type: 'password',
		};
		if (data.fields.phone) {
			data.fields.phone.type = 'phone';
			data.fields.phone_country_code.type = 'country_code';
		}
		if (data.fields.locations) {
			data.fields.locations.type = 'locations';
		}
		return data;
	}

	public loadLocale() {
		//return false; /// Remove when go live
		let user = this.user;
		let saved: any = this.storage.getItem('locale');
		let url: string;
		let lang: string;
		let settings = this.settings.getSettings();
		if (!user && !saved && settings) {
			url = `/groups/${this.settings.getGroupId()}/program_settings/labels?rev=${
				settings.locale_revision
			}&locale=${settings.default_locale}`;
			lang = settings.default_locale;
		} else if (user && saved && settings && user.locale !== saved.lang) {
			url = `/groups/${this.settings.getGroupId()}/program_settings/labels?rev=${
				settings.locale_revision
			}&locale=${user.locale}`;
			lang = user.locale;
		} else if (
			saved &&
			settings &&
			saved.rev !== settings.locale_revision
		) {
			url = `/groups/${this.settings.getGroupId()}/program_settings/labels?rev=${
				settings.locale_revision
			}&locale=${saved.lang}`;
			lang = saved.lang;
		}

		if (url && lang) {
			this.http.get(url).subscribe((data) => {
				let labels = data[lang.toLowerCase()];
				let locale = { lang, labels, rev: settings.locale_revision };
				this.storage.setItem('locale', locale);
				this.changeLocale(locale);
			});
		} else if (saved) {
			this.changeLocale(saved);
		}
	}

	changeLocale(data) {
		this.translate.setTranslation(data.lang, data.labels);
		this.translate.use(data.lang);
	}

	loadFavoritesBusinesses() {
		this.http
			.get(`/users/${this.user?.member_id}/businesses/favorites`)
			.subscribe((data) => {
				data.map((b) => (this.favouriteBusinesses[b.id] = b.name));
			});
	}

	toggleFavoritesBusiness(id) {
		if (this.favouriteBusinesses[id]) {
			delete this.favouriteBusinesses[id];
			this.http
				.delete(
					`/users/${this.user?.member_id}/businesses/${id}/favorites`,
					null,
					{ skipLoader: true }
				)
				.subscribe();
		} else {
			this.favouriteBusinesses[id] = 1;
			this.http
				.post(
					`/users/${this.user?.member_id}/businesses/${id}/favorites`,
					null,
					{ skipLoader: true }
				)
				.subscribe();
		}
	}

	loadAddresses(callback?: Function) {
		if (this.user) {
			this.http
				.get(`/users/${this.user.member_id}/addresses`)
				.subscribe((data) => {
					this.commit('setAddresses', data.addresses);
					callback?.(this.addresses);
				});
		} else {
			this.commit(
				'setAddresses',
				this.storage.getItem('myAddresses') || []
			);
			callback?.(this.addresses);
		}
	}

	validateAddress({ address, callback }) {
		this.http
			.post(
				`/groups/${this.settings.getGroupId()}/validations/address`,
				address,
				{ observe: 'response' }
			)
			.subscribe(
				(res) => {
					callback?.(res.body);
				},
				(err) => this.settings.flashWarning(err.error)
			);
	}

	saveAddress({ address, callback }) {
		if (this.user) {
			let url = address.id
				? `/users/${this.user.member_id}/addresses/${address.id}`
				: `/users/${this.user.member_id}/addresses`;
			this.http.post(url, address, { observe: 'response' }).subscribe(
				(res) => callback?.(res.body),
				(err) => this.settings.flashWarning(err.error)
			);
		} else {
			this.addresses.push(address);
			this.storage.setItem('myAddresses', this.addresses);
			callback?.();
		}
	}

	removeAddress({ index, callback }: any) {
		if (this.user) {
			this.http
				.delete(
					`/users/${this.user.member_id}/addresses/${this.addresses[index].id}`,
					null,
					{ observe: 'response' }
				)
				.subscribe(
					() => {
						this.addresses.splice(index, 1);
						callback?.();
					},
					(err) => this.settings.flashWarning(err.error)
				);
		} else {
			this.addresses.splice(index, 1);
			this.storage.setItem('myAddresses', this.addresses);
			callback?.();
		}
	}

	signOut() {
		this.commit('setUser', null);
		this.cart.commit('resetCart', true);
		this.storage.removeItem('locale');
		this.settings.loadSettings(() => {
			this.loadLocale();
		});
		this.router.navigate(['/']);
	}
}
