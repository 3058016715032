import {Component, OnInit} from '@angular/core';
import {Store} from "../../store/store.service";
import {Subscription} from "rxjs";
import * as JsBarcode from 'jsbarcode';

@Component({
	selector: 'app-gift-promt',
	templateUrl: './gift-promt.component.html'
})
export class GiftPromtComponent implements OnInit {

	show = false;
	card: any;
	sub: Subscription;
	cart: any = this.store.cart.getCartObject();

	constructor(private store: Store) {
	}

	ngOnInit(): void {
		this.sub = this.store.giftPromt.getStream$().subscribe(() => {
			this.card = this.store.giftPromt.getCard();
			this.show = true;
			setTimeout(() => {
				JsBarcode(".barcode").init();
			})
		});
	}

	close() {
		this.show = false;
	}
}
