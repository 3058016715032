import { Injectable } from '@angular/core';
import {BaseStore} from "./base-store.service";


@Injectable({
  providedIn: 'root'
})
export class CheckoutPromptService extends BaseStore {

	private settings = {
		content: "",
		buttonConfirm: "",
		buttonCancel: "",
		deny: null,
		success: null
	}

	getSettings(){
		return this.settings;
	}

  	constructor() {
		super();
   	}

   private setCheckoutPrompt(val){
		this.settings = val;
	}

	show(val){
		this.commit('setCheckoutPrompt', val);
	}

}
