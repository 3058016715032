import { Injectable, Injector } from '@angular/core';
import { BaseStore } from './base-store.service';
import { LocalStorageService } from '../services/local-storage.service';
import * as moment from 'moment';
import { HttpService } from '../services/http.service';
import { SettingsStore } from './settings.service';
import { MenuStore } from './menu.service';
import { LocationStore } from './location.service';
import { Router } from '@angular/router';
import { AuthStore } from './auth.service';
import { PromtStore } from './promt.service';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map } from 'rxjs/operators';
import { GiftCardsStore } from './gift-cards.service';
import { Observable, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

declare var window;

@Injectable({
	providedIn: 'root',
})
export class CartStore extends BaseStore {
	private cart: any = {};
	private updateDate: any = null;
	private remoteCartTimeout = null;
	public authStore;
	public orderInterval: any;
	public orderThreshold: any;
	public selectedOffer = '0.00';
	public cartSubtotal = this.cart.subTotal;
	public cartDisplayData: any;
	public priceData: any;
	public userData: any;
	private refreshCart = new BehaviorSubject<boolean>(false);
	refreshCartData = this.refreshCart.asObservable();
	private priceDataInd = new BehaviorSubject<boolean>(false);
	priceDataIndicator = this.priceDataInd.asObservable();
	public userInfo = this.storage.getItem('user') || null;
	private business_ID = null;
	constructor(
		private storage: LocalStorageService,
		private menu: MenuStore,
		private http: HttpService,
		private settings: SettingsStore,
		private locations: LocationStore,
		private router: Router,
		private injector: Injector,
		private promt: PromtStore,
		private translate: TranslateService
	) {
		super();
		setTimeout(() => {
			this.authStore = this.injector.get(AuthStore);
			this.resetCart(true);
			this.cart.calcOrderTime = calcOrderTime.bind(this.cart);
			this.cart.calcTotalCartQuantity = calcTotalCartQuantity.bind(
				this.cart
			);
			this.cart.subTotal = subTotal.bind(this.cart);
			this.cart.generateData = generateData.bind(this.cart);
		});
	}

	//######################################
	//Getters

	public getCartObject() {
		return this.cart;
	}

	public getOrderType() {
		return this.cart.orderType;
	}

	public saveUserData(val) {
		this.userData = val;
	}

	public getUserData() {
		if (this.userData) {
			return this.userData
		} else if (this.storage.getItem('user')) {
			const user = this.storage.getItem('user');
			return user
		} else {
			return {
				email: '',
				first_name: '',
				last_name: '',
				phone: '',
			};
		}
	}

	public resetUserData() {
		this.userData = null;
	}

	changeCartTrue() {
		this.refreshCart.next(true);
	}

	changeCartFalse() {
		this.refreshCart.next(false);
	}

	changeInPriceDataTrue() {
		this.priceDataInd.next(true);
	}

	getPriceData() {
		return this.priceData;
	}

	getCartMarketingStatus() {
		return this.settings?.getSettings()?.features?.cart_remarketing_enabled;
	}

	public getOrderTime() {
		return this.cart.calcOrderTime();
	}

	public getBusiness() {
		return this.cart.business;
	}

	public getCateringType() {
		return this.cart.cateringType;
	}

	public setGCData(data) {
		this.cart.giftCards = data;
	}


	public setGCAmount(amt) {
		this.cart.giftCards.amount = amt;
	}

	public setGatewayData(val) {
		this.cart.gateway = val;
	}

	public isGiftCardOrder(){
		return this.storage.getItem('orderType')?.gc
	}

	//######################################
	//Mutations

	private setOrderType(val) {
		this.cart.orderType = val;
	}

	private setAddTime(val) {
		this.cart.addTime = val;
	}

	private setDoorDashTime(val) {
		this.cart.doorDashTime = val;
	}

	private setOrderTime(val) {
		this.cart.orderTime = val;
	}

	private setCompleteOrder(val) {
		this.cart.completeOrder = val;
	}

	private setSubTotals(val) {
		this.cart.totals = val;
	}

	private setBusiness(val) {
		val.paymentSettings = val?.orderSettings?.payment_settings?.reduce(
			(prev, item) => {
				if (item.key === 'credit_card' && item.enabled) {
					this.cart.paymentMethod = item.key;
				} else if (!this.cart.paymentMethod && item.enabled) {
					this.cart.paymentMethod = item.key;
				}
				prev[item.key] = item.enabled;
				return prev;
			},
			{}
		);
		this.cart.business = val;
	}

	private setGateway(val) {
		this.cart.gateway = val;
	}

	private setAddress(val) {
		this.cart.address = val;
	}

	private setDDTime(val) {
		this.cart.ddOrderTime = val;
	}

	private addProduct(val) {
		this.cart.products.push(val);
	}

	private removeProduct(index: number) {
		this.cart.products.splice(index, 1);
	}

	private updateProduct(val: any) {
		this.cart.products[val.index] = val.product;
	}

	private updateCartDate() {
		this.updateDate = new Date();
	}

	private saveTempGC(val) {
		if (
			!this.cart.tempGCards.find(
				(gc) => gc.card_number === val.card_number
			)
		) {
			this.cart.tempGCards.push(val);
		}
	}

	private toggleOffer(offer) {
		let foundIndex = this.cart.discounts.findIndex(
			(o) => o.offer_id == offer.offer_id
		);
		if (foundIndex === -1) {
			if (!this.cart.allows_multiple_order_offers) {
				// TODO: find this value
				this.cart.discounts.splice(0, this.cart.discounts.length);
			}
			this.cart.discounts.push(offer);
		} else {
			this.cart.discounts.splice(foundIndex, 1);
		}
	}

	//#########################################
	//Action

	checkSaved(callback?: any) {
		if (this.authStore.isLoggedIn()) {
			this.loadRemoteCart(callback);
		} else {
			this.generateCartFromLocal();
			callback?.();
		}
	}

	resetCart(skipDeleteRemote: boolean = false) {
		this.cart.orderType = ''; // regular or catering
		this.cart.orderMethod = ''; //all methods
		this.cart.orderFamily = ''; //delivery and not delivery
		this.cart.address = null;
		this.cart.addTime = 0;
		this.cart.doorDashTime = null;
		this.cart.products = [];
		this.cart.card = null;
		this.cart.discounts = [];
		this.cart.user_info = {};
		this.cart.totals = null;
		this.cart.gateway = null;
		this.cart.paymentMethod = '';
		this.cart.apply_points = false;
		this.cart.tempCards = [];
		this.cart.giftCards = {};
		this.cart.tempGCards = [];
		this.cart.orderTime = null;
		this.cart.hasRemoteCart = false;
		this.cart.business = null;
		delete this.cart.cartSubtotal;
		if (!skipDeleteRemote && this.authStore.isLoggedIn()) {
			this.deleteRemoteCart();
		}
	}

	clearProducts() {
		this.cart.products.splice(0, this.cart.products.length);
	}

	loadGatewayInfo(
		{ callback, giftcardTypeId }: any = { giftcardTypeId: null }
	) {
		let url = '';
		let siteUrl = window.cordova
			? window.asoc.approvedCreditCardURL
			: window.location.href;
		if (giftcardTypeId) {
			url = `/group/${this.settings.getGroupId()}/settings/payments/gift_card_types/${giftcardTypeId}?url=${siteUrl}`;
			this.storage.setItem('orderType', { gc: true });
		} else if (this.cart.business?.business_id) {
			url = `/businesses/${this.cart.business.business_id}/settings/payments/orders?url=${siteUrl}`;
			this.storage.setItem('orderType', { gc: false });
		} else {
			return false;
		}
		this.http.get(url).subscribe((data) => {
			data.giftcard = giftcardTypeId;
			this.cart.gateway = data;
			this.commit('updateCartDate');
			callback?.(data);
		});
	}

	loadTotals({ data, callback }: any = {}) {
		data = data || this.cart.generateData();
		this.http
			.post(
				`/businesses/${this.cart.business.business_id}/orders/prices/validation`,
				data,
				{ observe: 'response' }
			)
			.subscribe(
				(res) => {
					const tips = null;
					this.commit('setSubTotals', { ...res.body?.totals, tips });
					callback?.(res.body);
				},
				(err) => {
					this.commit('setSubTotals', null);
					this.settings.flashWarning(err.error);
					callback?.(err);
				}
			);
	}

	loadUserCards({ user_id, callback, category, entity_id }: any) {
		let url = `/users/${user_id}/credit_cards?category=${category}&entity_id=${entity_id}`;
		this.http.get(url).subscribe((data) => callback?.(data));
	}

	saveCreditCard({ callback, data, user }: any) {
		this.http.post(`/users/${user}/credit_cards`, data).subscribe(
			(data) => {
				callback?.(data);
			},
			(err) => {
				callback?.(err);
			}
		);
	}

	deleteCreditCard({ id, callback, user_id }: any) {
		this.http
			.delete(`/users/${user_id}/credit_cards/${id}`)
			.subscribe(() => callback?.());
	}

	proceedOrder() {
		let data = this.cart.generateData(true);
		return this.http.post(
			`/businesses/${this.cart.business.business_id}/orders`,
			data,
			{ observe: 'response' }
		);
	}

	public azul3dsChallange(orderId: number) {
		return this.http.get(`/orders/${orderId}/payment/challenge`);
	}

	public orderContinue(orderId: number) {
		return this.http.post(`/unauth/orders/${orderId}/processing/continue`);
	}

	loadCheckInSettings(callback?) {
		const bid =
			this.cart.completeOrder?.summary?.business_info?.business_id;
		if (bid) {
			this.http
				.get(`/businesses/${bid}/check_in/settings`)
				.subscribe((data) => {
					callback(data);
				});
		}
	}

	checkIn({ spot, callback }: { spot: number; callback?: any }) {
		let order = this.cart.completeOrder?.summary;
		this.http
			.post(
				`/orders/${order.id}/check_in`,
				{ check_in_id: spot },
				{ observe: 'response' }
			)
			.subscribe(
				(res) => {
					order.checkedInSpot = spot;
					callback?.(res.body);
					this.commit('updateCartDate');
				},
				(err) => {
					this.settings.flashWarning(err.error);
				}
			);
	}

	loadLatestLeadTime({
		business_id,
		callback,
	}: {
		business_id?: any;
		callback?: any;
	}) {
		business_id = business_id || this.cart.business.business_id;
		this.http
			.get(
				`/businesses/${business_id}/orders/settings?order_method=${this.cart.orderMethod}`
			)
			.subscribe((data) => {
				this.commit('setAddTime', +data.lead_time);
				callback?.(data);
			});
	}

	loadDoorDashEstimate(callback: any = null) {
		const cartData = this.cart.generateData();
		delete cartData?.order_info?.pickup_datetime_utc;
		this.http
			.post(
				`/businesses/${this.cart.business.business_id}/orders/estimate`,
				cartData,
				{ observe: 'response' }
			)
			.subscribe(
				(res) => {
					res.body.ready_time = moment
						.utc(res.body.ready_at_datetime_utc)
						.local();
					res.body.pickup_time = moment
						.utc(res.body.pickup_datetime_utc)
						.local();
					this.commit('setDoorDashTime', res.body);
					callback?.(res.body);
				},
				(err) => {
					this.settings.flashWarning(err.error);
					this.router.navigate(['/cart']);
				}
			);
	}

	generateCartFromLocal() {
		let savedCart = this.storage.getItem('cart');
		if (savedCart) {
			savedCart.orderTime = moment(savedCart.orderTime);
			for (let i = 0; i < savedCart.products.length; i++) {
				const p = savedCart.products[i];
				savedCart.products[i] = p.isCombo
					? this.menu.initComboItem(p)
					: this.menu.initSingleProduct(p);
			}
			Object.keys(savedCart).map((k) => {
				this.cart[k] = savedCart[k];
			});
		}
	}

	generateCartFromData(data: any) {
		this.resetCart(true);
		let orderMethods = this.settings.getOrderMethods();
		let cart: any = this.cart;
		if (data.delivery_info?.address_id) {
			this.authStore.loadAddresses((addresses) => {
				this.cart.address = addresses.find(
					(el) => el.id === data.delivery_info.address_id
				);
				if (!this.cart.address) {
					this.settings.flashWarning('error.address_not_found', true);
					this.commit('resetCart');
				}
			});
		}
		let business = this.locations.getBusinessByID(
			data?.business_info?.business_id
		);
		cart.orderType = business?.order_method_family;
		cart.orderFamily = data?.family;
		cart.orderMethod = data?.method_key;
		cart.comment = data?.order_comment;
		cart.methodBusinessListSettings =
			orderMethods[cart.orderType][cart.orderMethod];
		business.orderSettings = cart.methodBusinessListSettings.find(
			(el) => el.business_id === business.business_id
		);
		if (!business.orderSettings) {
			return false;
		}
		cart.addTime = business.orderSettings.settings.lead_time;
		this.commit('setBusiness', business);
		cart.orderTime = moment().add(+cart.addTime + 1, 'minutes');
		data.items.map((p) => {
			let product = {
				needData: true,
				isCombo: false,
				menu_id: p.item_id,
				comment: p.comment,
				menu_title: p.title,
				quantity: p.quantity,
				photo_small: p.photo_small,
				photo_large: p.photo_large,
				price: p.price,
				quantityStep: 1,
				quantityMin: 1,
				activeModifiers: {},
			};
			p.modifiers.map((m) => {
				product.activeModifiers[m.modifier_id] = {
					addon_id: m.modifier_group_id,
					...m,
				};
			});
			product = this.menu.initSingleProduct(product);
			cart.products.push(product);
		});
		data.combo_meals.map((c) => {
			let combo: any = {
				needData: true,
				isCombo: true,
				combo_meal_id: c.combo_id,
				combo_title: c.combo_title,
				main_menu: {
					menu_id: c.item_id,
					activeModifiers: {},
					price: 0,
				},
				comment: c.comment,
				main_menu_item_title: c.main_menu_item_title,
				quantity: c.quantity,
				photo_small: c.photo_small,
				photo_large: c.photo_large,
				combo_meal_price: c.price,
				quantityStep: 1,
				quantityMin: 1,
				option_groups: [],
			};
			c.modifiers.map((m) => {
				combo.main_menu.activeModifiers[m.modifier_id] = {
					addon_id: m.modifier_group_id,
					...m,
				};
			});
			c.combo_options.map((co) => {
				co.menu_id = co.item_id;
				co.menu_title = co.title;
				co.activeModifiers = {};
				co.modifiers.map((m) => {
					co.activeModifiers[m.modifier_id] = {
						addon_id: m.modifier_group_id,
						...m,
					};
				});
				combo.option_groups.push({
					items: [
						{
							menu_item: co,
							price: co.price,
						},
					],
				});
			});
			combo = this.menu.initComboItem(combo);
			cart.products.push(combo);
		});
		this.commit('updateCartDate');
	}

	calculateSubtotal(offer, subTotal) {
		let currentValue = +offer.discount_value;
		if(offer && offer.discount_type?.toLowerCase() === 'percent') {
			currentValue = offer.discount_value/100;
			currentValue = currentValue * subTotal;
		}
		const subtotalcalc = subTotal - currentValue;
		if (subtotalcalc > 0) {
			return subtotalcalc.toFixed(2);
		} else {
			return '0.00';
		}
	}

	//#####################
	//Abandoned cart
	saveRemoteCart() {
		const data = this.cart.generateData();
		const user = this.authStore.getUser();

		this.http[this.cart.hasRemoteCart ? 'put' : 'post'](
			`/users/${user.member_id}/cart`,
			data,
			{ skipLoader: true }
		).subscribe((data) => {});
		this.cart.hasRemoteCart = true;
	}

	deleteRemoteCart() {
		const user = this.authStore.getUser();
		this.http
			.delete(`/users/${user.member_id}/cart`, null, { skipLoader: true })
			.subscribe();
	}

	loadRemoteCart(callback?: any) {
		const user = this.authStore.getUser();
		if (this.getCartMarketingStatus()) {
			this.http
				.get(`/users/${user.member_id}/cart`, {
					skipLoader: true,
					observe: 'response',
				})
				.subscribe(
					(res) => {
						this.generateCartFromData(res.body);
						this.cart.hasRemoteCart = true;
						callback?.();
					},
					(err) => {
						let data = this.storage.getItem('cart');
						if (data && data.business && data.products.length > 0) {
							this.generateCartFromLocal();
						} else {
							this.resetCart();
						}
						callback?.();
					},
					() => callback?.()
				);
		} else {
			this.generateCartFromLocal();
			callback?.();
		}
	}

	cancelOrder() {
		this.promt.show({
			content: this.translate.instant('order.cancel_order'),
			success: () => {
				this.commit('resetCart');
				clearInterval(this.orderInterval);
				this.router.navigate(['/']);
			},
		});
	}

	//Override
	commit(name: string, data?: any) {
		super.commit(name, data);
		this.storage.setItem('cart', this.cart);

		if (
			this.authStore.isLoggedIn() &&
			['addProduct', 'removeProduct', 'updateProduct'].includes(name)
		) {
			clearTimeout(this.remoteCartTimeout);
			this.remoteCartTimeout = setTimeout(() => {
				this.saveRemoteCart();
			}, 100);
		}
	}

	incrementOrderTime() {
		this.orderInterval = setInterval(() => {
			this.cart.orderTime = this.getOrderTime();
		}, 1000);
	}
}

//#########################################
//Helpers for cart Object
function calcOrderTime(
	isInstance: boolean = false,
	noDoorDash: boolean = false
) {
	// if isInstance true return true or false

	if (
		!isInstance &&
		!noDoorDash &&
		moment.isMoment(this.doorDashTime?.ready_time)
	) {
		return moment(this.doorDashTime.ready_time);
	}

	const allowedTime = moment().add(
		parseInt(this.business.orderSettings.settings.lead_time),
		'minutes'
	);
	if (this.orderTime.isAfter(allowedTime)) {
		return isInstance ? false : moment(this.orderTime);
	} else {
		return isInstance ? true : allowedTime;
	}
}

function calcTotalCartQuantity(): number {
	let total = 0;
	this.products.map((el) => {
		total += el.quantity;
	});
	return total;
}

function subTotal(): any {
	let total = 0;
	this.products.map((el) => {
		total += +el.totalPrice();
	});
	return total;
}

function generateData(realTime: boolean = false) {
	let items = [];
	let combo_meals = [];

	this.products.map((p) => {
		if (p.isCombo) {
			let combo = {
				combo_meal_id: p.combo_meal_id,
				quantity: p.quantity,
				comment: p.comment,
				modifier_groups: {},
				combo_options: [],
			};
			generatModifiersGroups(combo, p.main_menu.activeModifiers);
			p.option_groups.forEach((og) => {
				let activeItem = og.items.find(
					(item) => item.menu_item.menu_id === og.active_item
				);
				let comboOption = {
					group_id: og.id,
					item_id: activeItem.menu_item.menu_id,
					quantity: 1,
					modifier_groups: {},
				};
				generatModifiersGroups(
					comboOption,
					activeItem.menu_item.activeModifiers
				);
				combo.combo_options.push(comboOption);
			});
			combo_meals.push(combo);
		} else {
			let product: any = {
				item_id: p.menu_id,
				comment: p.comment,
				quantity: p.quantity,
				modifier_groups: {},
			};
			generatModifiersGroups(product, p.activeModifiers);
			items.push(product);
		}
	});
	let paymentData = null;
	if (
		this.paymentMethod === 'credit_card' &&
		this.gateway?.gateway_type != 'cardconnect'
	) {
		paymentData = this.card?.credit_card_token
			? { type: 'cc_token', token: this.card.credit_card_token }
			: { type: 'cc_id', id: this.card?.credit_card_id };
	} else if (
		this.paymentMethod === 'credit_card' &&
		this.gateway?.gateway_type === 'cardconnect'
	) {
		paymentData = { type: 'cc_id', id: this.card?.credit_card_id };
	}

	let order_time = this.business && this.calcOrderTime(false, true);

	let data = {
		method_key: this.orderMethod,
		user_id: this.user_info?.member_id,
		business_id: this.business?.business_id,
		guest_id:
			!this.user_info?.member_id && this.gateway?.customer_id
				? this.gateway.customer_id
				: null,
		user_info: {
			...this.user_info,
		},
		delivery_info: {
			address_id: this.address?.id,
			address: this.address?.id ? null : this.address?.address_components,
		},
		payments: {
			money: {
				method: this.paymentMethod,
				data: paymentData,
			},
			gift_cards: { ...this.giftCards },
			points: this.apply_points,
			tip: this.totals?.tips || 0,
		},
		discounts: this.discounts.map((o) => o.offer_id),
		order_info: {
			is_instant: order_time && this.calcOrderTime(true),
			comment: this.comment,
			pickup_datetime_utc:
				realTime && this.doorDashTime
					? this.doorDashTime.pickup_time
							.utc()
							.format('YYYY-MM-DD HH:mm:ss')
					: order_time &&
					  order_time.utc().format('YYYY-MM-DD HH:mm:ss'),
			ready_at_datetime_utc:
				realTime && this.doorDashTime
					? this.doorDashTime.ready_time
							.utc()
							.format('YYYY-MM-DD HH:mm:ss')
					: order_time &&
					  order_time.utc().format('YYYY-MM-DD HH:mm:ss'),
		},
		items,
		combo_meals,
	};
	return data;
}

function generatModifiersGroups(object, activeModifiers) {
	for (let [key, value] of Object.entries(activeModifiers)) {
		if (!object.modifier_groups[value['addon_id']]) {
			object.modifier_groups[value['addon_id']] = {
				id: value['addon_id'],
				modifiers: {},
				type: value['key'] ? 'pizza' : null,
			};
		}
		object.modifier_groups[value['addon_id']].modifiers[key] = {
			id: value['modifier_id'],
			quantity: value['quantity'] || 1,
		};
	}
	object.modifier_groups = Object.values(object.modifier_groups);
	object.modifier_groups.forEach(
		(mg: any) => (mg.modifiers = Object.values(mg.modifiers))
	);
}
