import { Injectable } from "@angular/core";
import {Store} from "../store/store.service";

@Injectable()
export class GoogleMapsConfigInterceptor {
	apiKey: string;
	libraries= ['places'];
	//this does not work with asynchronous methods due to concurrency
	constructor(private store: Store) {
		this.apiKey = this.store.settings.getSettings()?.link_variables?.google_api_key
	}
}
