import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Store } from '../../store/store.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-track',
	templateUrl: './track.component.html',
	styleUrls: ['track.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackComponent implements OnInit {
	public id: string;
	public trackerUrl: SafeResourceUrl;

	constructor(
		private activatedRoute: ActivatedRoute,
		private sanitizer: DomSanitizer,
		private store: Store,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.id = this.activatedRoute.snapshot.paramMap.get('id');
		this.store.settings.topBar({
			showBackButton: true,
			showBag: false,
			title: this.translate.instant('track_order.your_order'),
		});

		const params = new HttpParams()
			.append('o', this.id)
			.append('embedded', '');
		this.trackerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
			`${environment.driverTrackerUrl}?${params.toString()}`
		);
	}
}
