import {Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'app-loyalty-profile',
	templateUrl: './loyalty-profile.component.html'
})
export class LoyaltyProfileComponent implements OnInit {

	@Input() data:any;
	@Input() dummy: boolean;

	constructor() {
	}

	ngOnInit(): void {
		this.data.percentage = ((this.data.total_points - this.data.min_current_level) * 100) / (this.data.max_current_level - this.data.min_current_level)
	}

}
