import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '../../store/store.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { CartStore } from '../../store/cart.service';
import { GiftCardsStore } from 'src/app/store/gift-cards.service';

@Component({
	selector: 'app-payment-section',
	templateUrl: './payment-section.component.html',
})
export class PaymentSectionComponent implements OnInit, OnDestroy {
	@Input() title = this.store.translate.instant('payment.method');
	@Input() list;
	@Input() hideNavs;
	@Input() asSlider;
	@Input() noActive = false;
	@Input() category;
	@Input() entityID;
	@Input() showRequired: boolean;
	condition: boolean = false;
	cards: any;
	cart: any = this.store.cart.getCartObject();
	sub: Subscription;
	user = this.store.auth.getUser();
	paymentTypes: { pay_method: string; title: string }[] = [];
	cardBrandClass = {
		visa: 'fab fa-cc-visa',
		mastercard: 'fab fa-cc-mastercard',
		mc: 'fab fa-cc-mastercard',
		americanexpress: 'fab fa-cc-amex',
		discover: 'fab fa-cc-discover',
		diners: 'fab fa-cc-diners-club',
		jcb: 'fab fa-cc-jcb',
		unknown: 'fab fa fa-credit-card',
	};

	constructor(public store: Store, public router: Router) {}

	ngOnInit(): void {
		this.initCards();
		this.sub = this.store.cart.getStream$().subscribe((data) => {
			if (data.name === 'updateCartDate') {
				this.initCards(true);
			}
		});
	}

	initCards(refresh = false) {
		this.paymentTypes = [];
		if (this.list && !refresh) {
			this.cards = this.list;
			this.cart.card = this.cards[0] ? this.cards[0] : null;
		} else {
			if (this.user || this.cart.gateway?.gateway_type === 'authorize') {
				setTimeout(() => {
					// <<<---using ()=> syntax
					this.store.cart.loadUserCards({
						user_id:
							this.user?.member_id ||
							this.cart.gateway?.customer_id,
						category: this.category,
						entity_id: this.entityID,
						callback: (data) => {
							this.cards = data;
							this.cart.card = this.cards[0]
								? this.cards[0]
								: null;
						},
					});
				}, 1200);
			} else if (this.cart.tempCards?.length) {
				this.cards = this.cart.tempCards;
				this.cart.card = this.cards[0];
			}
		}
	}

	addCard() {
		if (this.cart?.giftCards?.agreed_terms_conditions === false) {
			this.router.navigate(['/buy-ewallet']);
		}
	}

	removeCard(ev, index) {
		ev.stopPropagation();
		this.store.promt.show({
			content: this.store.translate.instant('payment.delete_card_promt'),
			buttonConfirm: this.store.translate.instant('payment.remove'),
			success: () => {
				this.store.cart.deleteCreditCard({
					id: this.cards[index].credit_card_id,
					user_id: this.user?.member_id,
					callback: () => {
						this.cards.splice(index, 1);
						this.cart.card = this.cards[0] ? this.cards[0] : null;
					},
				});
			},
		});
	}

	selectCard(card) {
		this.cart.card = card;
		this.store.cart.commit('updateCartDate');
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}
}
