<div class="container my-4" *ngIf="order && !order.is_active">
	<h3
		class="
			mb-md-4
			mt-md-5
			mb-2
			justify-content-between
			d-flex
			align-items-end
			lineHeight1
		"
	>
		{{ "home.previous_order" | translate }}
		<a
			routerLink="/orders"
			class="color7 h5"
			[class]="{ 'd-none': dummy }"
			>{{ "home.see_more" | translate }}</a
		>
	</h3>
	<div class="position-relative"  *ngFor="let order of orders | keyvalue">
		<app-dummy-placeholder *ngIf="dummy"></app-dummy-placeholder>
		<div class="container930" [class.dummyContent]="dummy">
			<div class="border rounded-lg py-2 px-2">
				<div class="orderHistory">
					<div
						class="
							d-flex
							justify-content-between
							py-1
							align-items-end
						"
					>
						<div>
							<h3>
								{{ "home.order" | translate }} #{{
									order.value.check_number || order.value.order_number
								}}
							</h3>
						</div>
						<h3 class="pl-2 color2">
							{{ order.value.currency?.symbol
							}}{{ order.value.totals?.total }}
						</h3>
					</div>
				</div>
				<div class="orderHistory">
					<div
						class="
							d-flex
							justify-content-between
							py-1
							align-items-end
						"
					>
						<div>
							<h5>
								{{ "order." + order.value.method_key | translate }}
							</h5>
						</div>
						<h6 class="pl-2">
							{{ order.value.ready_at_datetime_utc | MomentPipe: "MM/DD/YYYY" }}
						</h6>
					</div>
				</div>


				<div class="mt-3">
					<div class="actions-container">
						<a
							mat-raised-button
							class="mr-1"
							(click)="store.auth.reOrder(order.value.id)"
							>{{ "home.reorder" | translate }}</a
						>
						<a
							mat-raised-button
							class="ml-1"
							[routerLink]="'../order/' + order.value.id"
							>{{ "home.view" | translate }}</a
						>
					</div>

					<a
						*ngIf="order.value.tracking_id"
						mat-raised-button
						class="mt-2"
						[routerLink]="'../track/' + order.value.tracking_id"
						>{{ "home.track" | translate }}</a
					>
				</div>
			</div>
		</div>
	</div>
</div>
