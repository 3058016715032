import { Component, Input, OnInit } from '@angular/core';
import { Store } from '../../../store/store.service';

@Component({
	selector: 'app-previous-order',
	templateUrl: './previous-order.component.html',
	styleUrls: ['previous-order.component.scss'],
})
export class PreviousOrderComponent implements OnInit {
	@Input() currency: any;
	@Input() dummy: any;
	@Input() order: any;

	orders: any = [];

	constructor(public store: Store) {}

	ngOnInit(): void {
		if(this.store.auth.isLoggedIn() && this.order) {
			this.loadPreviousOrder();
		} else {
			this.orders.push(this.order)
		}
	};

	loadPreviousOrder() {
		this.store.auth.loadOrderHistory().subscribe((orders) =>{
			let i;
			for (i of orders){
				if (!i.is_active) {
					this.orders.push(i);
					break;
				}
			}
		});
	}
}
