<div>
	<div id="topBar" [class]="{active: settings.active}">
		<div class="box">
			<div class="leftIcons d-none d-md-block">
				<a routerLink="/home">
					<img class="logo" [src]="store.settings.getSettings()?.group_logo">
				</a>
				<a class="nav colorWhite ml-3" (click)="store.settings.commit('setShowNav', true)">
					{{"nav.menu_top_nav" | translate}}
				</a>
				<a class="nav colorWhite ml-4" routerLink="/locations">
					{{"nav.locations_top_nav" | translate}}
				</a>
			</div>
			<div class="leftIcons d-xl-none d-lg-none d-md-none">
				<div *ngIf="settings.showBackButton" class="backIcon" (click)="location.back()"></div>
				<div *ngIf="settings.showX" class="cancelOrder" (click)="store.cart.cancelOrder()"></div>
				<div *ngIf="settings.showNav" class="navBurger" (click)="store.settings.commit('setShowNav', true)"></div>
			</div>
			<!---->
			<div class="centered col-6">
				<div>
					<div class="title h1 mb-0 color8" *ngIf="settings.title">
						{{settings.title}}
					</div>
					<!-- <app-location-search-box  *ngIf="settings.showSearchPosition"></app-location-search-box>
					<div class="captText color6 text-nowrap addressTime" *ngIf="settings.showAddressTime && bAddress">
						{{bAddress.address1}} {{bAddress.city}}, {{bAddress.state}} {{bAddress.zipcode}} <br>
						{{ "order." + cart.orderMethod | translate}}, {{ cart.orderTime.format("MM/DD/YYYY hh:mm A") }}
					</div> -->
				</div>
			</div>
			<!---->

			<div class="rightIcons">

				<span *ngIf="!store.auth.isLoggedIn()" class="loginBox d-none d-md-block">
					<a class="colorWhite nav text-transform-upper" routerLink="/signup">
						{{'account.join' | translate}}
					</a>
					/
					<a class="colorWhite nav text-transform-upper" routerLink="/login">
						{{'account.signin' | translate}}
					</a>
				</span>
				<a routerLink="/member-card" *ngIf="store.auth.isLoggedIn()" >
					<div class="qrCodeIcon"></div>
				</a>
				<a  *ngIf="settings.showBag && appSettings.show_previous_order" class="bagBox" routerLink="/cart" >
					<div class="bagIcon"></div>
					<div *ngIf="cart.calcTotalCartQuantity()" class="quantity">
						<span>{{ (cart.calcTotalCartQuantity() < 100) ? cart.calcTotalCartQuantity() : "99+" }}</span>
					</div>
				</a>
				<div *ngIf="settings.showNav" class="navBurger d-none d-md-block" (click)="store.settings.commit('setShowNav', true)"></div>
			</div>
		</div>
	</div>
</div>

