export default {
	dummy: true,
	"loyalty_profile": {
		"total_points": 1037,
		"available_points": 1037,
		"next_level": null,
		"max_current_level": 5000,
		"min_current_level": 1000,
		"status_level": "R. Platinum Member",
		"status_color": "#999999"
	},
	"gift_cards": [
		{
			"card_id": "3389282",
			"card_number": "945987934967094",
			"balance": "992.74",
			"currency": {
				"short": "USD",
				"symbol": "$"
			},
			"gift_card_type_id": "2466",
			"title": "Group Card",
			"allow_online_purchase": false,
			"allow_auto_recharge": false,
			"images": {
				"image": "https://ccp.mobileappsuite.com/assets/images/gift_card_types/default/image.png",
				"image_2x": "https://ccp.mobileappsuite.com/assets/images/gift_card_types/default/image_2x.png",
				"image_thumb_2x": "https://ccp.mobileappsuite.com/assets/images/gift_card_types/default/image_thumb_2x.png",
				"image_3x": "https://ccp.mobileappsuite.com/assets/images/gift_card_types/default/image_3x.png",
				"image_thumb_3x": "https://ccp.mobileappsuite.com/assets/images/gift_card_types/default/image_thumb_3x.png"
			},
			"all_businesses": false
		}
	],
	"offers": [
		{
			"redeemable_online": true,
			"offer_id": 12006,
			"offer_title": "$50 Reward",
			"offer_description": "<p style=\"color: rgb(0, 0, 0); font-size: 12px; background-color: rgb(255, 255, 255);\"><span style=\"font-family: verdana, geneva, sans-serif;\"><span style=\"font-size: 14px;\">*Must have enough available points to purchase this reward*</span></span></p>\n\n<p style=\"color: rgb(0, 0, 0); font-size: 12px; background-color: rgb(255, 255, 255);\"><em style=\"text-align: center;\">* Cannot be combined with any other offer-Cannot be redeemed for cash value&nbsp;*</em></p>",
			"offer_photo": "",
			"offer_type": "For Purchase",
			"purchase_amount": 500,
			"discount_type": "Exact amount",
			"discount_value": "50.00",
			"use_in_td": true,
			"min_amount": "0.00",
			"expiration": null,
			"pos_discount_id": "",
			"location_discounts": [
				{
					"business_id": 1438,
					"discount_value": "50.00",
					"pos_discount_id": ""
				},
				{
					"business_id": 1541,
					"discount_value": "50.00",
					"pos_discount_id": ""
				},
				{
					"business_id": 1542,
					"discount_value": "50.00",
					"pos_discount_id": ""
				},
				{
					"business_id": 1546,
					"discount_value": "50.00",
					"pos_discount_id": ""
				},
				{
					"business_id": 1555,
					"discount_value": "50.00",
					"pos_discount_id": ""
				}
			],
			"currency": null
		},
		{
			"redeemable_online": true,
			"offer_id": 12007,
			"offer_title": "$25 Reward",
			"offer_description": "<p style=\"color: rgb(0, 0, 0); font-size: 12px; background-color: rgb(255, 255, 255);\"><span style=\"font-family: verdana, geneva, sans-serif;\"><span style=\"font-size: 14px;\">*Must have enough available points to purchase this reward*</span></span></p>\n\n<p style=\"color: rgb(0, 0, 0); font-size: 12px; background-color: rgb(255, 255, 255);\"><em style=\"text-align: center;\">* Cannot be combined with any other offer-Cannot be redeemed for cash value&nbsp;*</em></p>",
			"offer_photo": "",
			"offer_type": "For Purchase",
			"purchase_amount": 250,
			"discount_type": "Exact amount",
			"discount_value": "25.00",
			"use_in_td": true,
			"min_amount": "0.00",
			"expiration": null,
			"pos_discount_id": "",
			"location_discounts": [
				{
					"business_id": 1438,
					"discount_value": "25.00",
					"pos_discount_id": ""
				},
				{
					"business_id": 1541,
					"discount_value": "25.00",
					"pos_discount_id": ""
				},
				{
					"business_id": 1542,
					"discount_value": "25.00",
					"pos_discount_id": ""
				},
				{
					"business_id": 1546,
					"discount_value": "25.00",
					"pos_discount_id": ""
				},
				{
					"business_id": 1555,
					"discount_value": "25.00",
					"pos_discount_id": ""
				}
			],
			"currency": null
		}
	],
	"credit_cards": [
		{
			"credit_card_id": 547616,
			"credit_card_number": "XXXX-XXXX-XXXX-4242",
			"name_on_card": "John Doe",
			"expiration_date": "0222",
			"card_type": "Visa",
			"one_time_use": false
		}
	],
	"previous_order": {
		"currency": {
			"short": "USD",
			"symbol": "$"
		},
		"method_key": "curbside",
		"family": "pick_up",
		"business_info": {
			"address": "401 E Ontario St",
			"email": "example@gmail.com",
			"zip": "60611",
			"phone": "5614273412",
			"name": "Appsuite Coffee Shop",
			"business_id": 1541
		},
		"totals": {
			"delivery_fee": "0.00",
			"service_fee": "0.00",
			"sub_total": "3.64",
			"tax": "0.15",
			"tip": "0.36",
			"discounts": [],
			"total": "4.15"
		},
		"id": 1305839,
		"order_comment": "",
		"ready_at_datetime_utc": "2021-05-20 04:53:22",
		"gift_cards": [
			{
				"id": 3389282,
				"card_number": "945987934967094",
				"amount": "992.74"
			}
		],
		"payment": null,
		"items": [
			{
				"quantity": 1,
				"item_id": 27158913,
				"price": "3.64",
				"title": "Item Name",
				"comment": "",
				"item_amount": "3.64"
			}
		]
	},
	dummy_user: {
		first_name: "John",
		last_name: "Doe",
		member_id: 1234567890,
	}
}
