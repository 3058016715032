<div class="container" *ngIf="isActive">
	<h3
		class="
			mb-md-4
			mt-2
			mb-2
			justify-content-between
			d-flex
			align-items-end
			lineHeight1
		"
	>
		Active Order
		<a
			routerLink="/orders"
			class="color7 h5"
			>{{ "home.see_more" | translate }}</a
		>
	</h3>
	<div class="position-relative pt-3" *ngFor="let order of orders | keyvalue">
		<div class="container930">
			<div class="border rounded-lg py-2 px-2">
				<div class="orderHistory">
					<div
						class="
							d-flex
							justify-content-between
							py-1
							align-items-end
						"
					>
						<div>
							<h3>
								{{ "home.order" | translate }} #{{
									order.value.check_number || order.value.order_number
								}}
							</h3>
						</div>
						<h3 class="pl-2 color2">
							{{ order.value.currency?.symbol }}
							{{ order.value.totals?.total }}
						</h3>
					</div>
				</div>
				<div class="orderHistory">
					<div
						class="
							d-flex
							justify-content-between
							py-1
							align-items-end
						"
					>
						<h5>
							{{ "order." + order.value.method_key | translate }}
						</h5>
						<h5 *ngIf="order.value.method_key != 'delivery' else deliveryLabel">
							Order Ready At
						</h5>
						<ng-template #deliveryLabel>
							<h5>
								Estimated Delivery Time
							</h5>
						</ng-template>
					</div>
					<div
					class="
						d-flex
						justify-content-between
						py-1
						align-items-end
					"
					>
						<h5 class="text-capitalize">
							{{ order.value.order_status | translate }}
						</h5>
						<h6 class="pl-2">
							{{ order.value.ready_at_datetime_utc | MomentPipe: "MM/DD/YYYY hh:mm A" }}
						</h6>
					</div>

				</div>


				<div class="mt-3">
					<div class="actions-container">
						<a
							mat-raised-button
							class="mr-1"
							(click)="store.auth.reOrder(order.value.id)"
							>{{ "home.reorder" | translate }}</a
						>
						<a
							mat-raised-button
							class="ml-1"
							[routerLink]="'../order/' + order.value.id"
							>{{ "home.view" | translate }}</a
						>
					</div>

					<a
						*ngIf="order.value.tracking_id"
						mat-raised-button
						class="mt-2"
						[routerLink]="'../track/' + order.value.tracking_id"
						>{{ "home.track" | translate }}</a
					>
				</div>
			</div>
		</div>
	</div>
</div>
