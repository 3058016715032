<div *ngFor="let f of fields | keyvalue: unsorted">
	<!--######-->
	<!--TEXT-->
	<!--######-->
	<mat-form-field class="w-100" *ngIf="f.value.type === 'string'" [appearance]="appearance">
		<input
			matInput
			type="text"
			[required]="f.value.required"
			[(ngModel)]="form[f.key]"
			[placeholder]="generatePlaceholder(f)"
		>
		<mat-hint *ngIf="hints[f.key]" align="start">{{hints[f.key]}}</mat-hint>
	</mat-form-field>
	<!--######-->
	<!--Numbers-->
	<!--######-->
	<mat-form-field class="w-100" *ngIf="f.value.type === 'integer'" [appearance]="appearance">
		<input
			matInput
			type="tel"
			pattern="^([-+,0-9.]+)"
			inputmode="tel"
			title="numbers only"
			[required]="f.value.required"
			[(ngModel)]="form[f.key]"
			[uiMask]="'9{1,}'"
			[uiMaskOptions]="{rightAlign: false, placeholder: '', showMaskOnHover: false, showMaskOnFocus: false,  allowPlus: false, allowMinus: true}"
			[placeholder]="generatePlaceholder(f)"
		>
		<mat-hint *ngIf="hints[f.key]" align="start">{{hints[f.key]}}</mat-hint>
	</mat-form-field>
	<mat-form-field class="w-100" *ngIf="f.value.type === 'decimal'" [appearance]="appearance">
		<input
			matInput
			type="tel"
			pattern="^([-+,0-9]+)"
			title="numbers only"
			[required]="f.value.required"
			[(ngModel)]="form[f.key]"
			[uiMask]="'decimal'"
			[uiMaskOptions]="{rightAlign: false, placeholder: '', showMaskOnHover: false, showMaskOnFocus: false,  allowPlus: false, allowMinus: true}"
			[placeholder]="generatePlaceholder(f)"
		>
		<mat-hint *ngIf="hints[f.key]" align="start">{{hints[f.key]}}</mat-hint>
	</mat-form-field>
	<!--######-->
	<!--DATE-->
	<!--######-->
	<mat-form-field class="w-100 mkdatepicker" *ngIf="f.value.type === 'date'" [appearance]="appearance">
		<input
			matInput
			(focus)="picker.open()"
			[matDatepicker]="picker"
			[required]="f.value.required"
			[(ngModel)]="form[f.key]"
			[placeholder]="generatePlaceholder(f)"
		>
		<mat-datepicker-toggle matSuffix [for]="picker">
			<mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
		</mat-datepicker-toggle>
		<mat-datepicker #picker></mat-datepicker>
		<mat-hint *ngIf="hints[f.key]" align="start">{{hints[f.key]}}</mat-hint>
	</mat-form-field>
	<!--######-->
	<!--EMAIL-->
	<!--######-->
	<mat-form-field class="w-100" *ngIf="f.value.type === 'email'" [appearance]="appearance">
		<input
			matInput type="email"
			[required]="f.value.required"
			[(ngModel)]="form[f.key]"
			[placeholder]="generatePlaceholder(f)"
		>
		<mat-hint *ngIf="hints[f.key]" align="start">{{hints[f.key]}}</mat-hint>
	</mat-form-field>
	<!--######-->
	<!--SELECT-->
	<!--######-->
	<mat-form-field class="w-100" *ngIf="f.value.type === 'select' && f.value.options" [appearance]="appearance">
		<mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
		<mat-select
			[placeholder]="generatePlaceholder(f)"
			[required]="f.value.required"
			[(ngModel)]="form[f.key]"
		>
			<mat-option *ngFor="let opt of f.value.options | keyvalue: unsorted" [value]="opt.key" [appearance]="appearance">
				{{opt.value}}
			</mat-option>
		</mat-select>
		<mat-hint *ngIf="hints[f.key]" align="start">{{hints[f.key]}}</mat-hint>
	</mat-form-field>
	<!--######-->
	<!--LOCATIONS-->
	<!--######-->
	<mat-form-field class="w-100 select" *ngIf="f.value.type === 'locations'" [appearance]="appearance">
		<mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
		<mat-select
			(selectionChange)="locationsChanged(f.value.options[$event.value].servers)"
			[(ngModel)]="form[f.key]"
			[placeholder]="generatePlaceholder(f)"
			[required]="f.value.required"
		>
			<mat-option *ngFor="let opt of f.value.options | keyvalue: unsorted" [value]="opt.key">
				{{opt.value.title}}
			</mat-option>
		</mat-select>
		<mat-hint *ngIf="hints[f.key]" align="start">{{hints[f.key]}}</mat-hint>
	</mat-form-field>
	<!--######-->
	<!--PASSWORD-->
	<!--######-->
	<mat-form-field class="w-100" *ngIf="f.value.type === 'password'" [appearance]="appearance">
		<input
			matInput [type]="hidePassword ? 'text' : 'password' "
			[required]="f.value.required"
			minlength="6"
			[(ngModel)]="form[f.key]"
			[placeholder]="generatePlaceholder(f)"
			[title]="hints[f.key]"
			pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
		>
		<mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
		<mat-hint *ngIf="hints[f.key]" align="start">{{hints[f.key]}}</mat-hint>
	</mat-form-field>
	<!--######-->
	<!--PHONE-->
	<!--######-->
	<div>
		<div *ngIf="f.value.type === 'phone'" class="d-flex">
			<div *ngIf="fields.phone_country_code" class="w-25">
				<mat-form-field class="w-100" [appearance]="appearance">
					<mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
					<mat-select
						[placeholder]="'account.country_code' | translate"
						[required]="fields.phone_country_code.required"
						[(ngModel)]="form.phone_country_code"
					>
						<mat-select-trigger>+{{form.phone_country_code}}</mat-select-trigger>
						<mat-option *ngFor="let opt of fields.phone_country_code.options | keyvalue: unsorted" [value]="opt.value">
							{{opt.key}} +{{opt.value}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div  class="flex-grow-1">
				<mat-form-field class="w-100" [appearance]="appearance">
					<input
						matInput
						inputmode="tel"
						type="tel"
						[uiMask]="'9{1,}'"
						[uiMaskOptions]="{rightAlign: false, placeholder: '', showMaskOnHover: false, showMaskOnFocus: false,  allowPlus: false, allowMinus: false }"
						[required]="f.value.required"
						[(ngModel)]="form[f.key]"
						[placeholder]="generatePlaceholder(f)"
						(keydown.enter)="$event.preventDefault()"
					>
					<!-- <mat-hint *ngIf="hints[f.key]" align="start">{{hints[f.key]}}</mat-hint> -->
					
				</mat-form-field>
			</div>
		</div>
		<div *ngIf="f.value.type === 'phone'" class="col-12" style="margin-bottom: 20px">
			<div class="mb-3 h3" style="font-weight: bolder;">
				{{settings.title}} {{"hints.phone_opt_in_header" | translate}}
			</div>
			<div class="mb-2">{{"hints.phone_opt_in" | translate}}</div>
			<div class="">
				<mat-checkbox
					[required]="fields.phone_country_code.required"
					[(ngModel)]="smsOptIn"
					value="1"
				></mat-checkbox> {{"hints.phone_opt_in_agree" | translate}}
			</div>
		</div>
	</div>

	<!--######-->
	<!--BOOLEAN-->
	<!--######-->
	<div class="w-100 pb-3"  *ngIf="f.value.type === 'boolean'">
		<mat-checkbox
			[required]="f.value.required"
			[(ngModel)]="form[f.key]"
			value="1"
		>{{'account.' + f.key | translate}}</mat-checkbox>
	</div>
</div>
