import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from "../../store/store.service";
import {Subscription} from "rxjs";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html'
})
export class LoaderComponent implements OnInit, OnDestroy {

	showLoader = this.store.settings.getPageLoader();
	private sub: Subscription;

	constructor(public store: Store,private router: Router,private route:ActivatedRoute) {
		
	}

	ngOnInit(): void {
		
		this.sub = this.store.settings.getStream$().subscribe(()=>{

			this.route?.queryParams.subscribe((result)=>{
			
				if(result?.lastPage=='/checkout'){
					this.showLoader =1;
					setTimeout(()=>{
						this.showLoader = this.store.settings.getPageLoader();
					},6000)
	  
				}else{
					this.showLoader = this.store.settings.getPageLoader();
				}
			  });
			
		})
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}

}
