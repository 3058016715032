import {Component, OnInit} from '@angular/core';
import {Store} from "../../store/store.service";
import dummy_data from "./dummy_data";

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

	user = this.store.auth.getUser();
	data: any = this.initData(this.store.settings.getSettings());

	constructor(public store: Store) {
	}

	ngOnInit(): void {
		this.store.menu.scrollPosition = null;
		!this.data && this.store.settings.loadSettings(data => {
			this.data = this.initData(data);
		});
		let title = this.store.auth.isLoggedIn()
			? this.store.translate.instant("home.hello") + (this.user?.first_name ? `, ${this.user.first_name}` : "")
			: this.store.translate.instant("home.hello_guest")
		this.store.settings.topBar({no_translate_title: title, showBag: true, showBackButton: false, showNav: true});
	}

	initData(data) {
		return (this.user?.member_id) ? data : {...dummy_data, ...data}
	}

}
