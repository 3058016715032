import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { HomeComponent } from './pages/home/home.component';
import { UnAuthGuard } from './guards/unauth.guard';
import { TrackComponent } from './pages/track/track.component';

declare var window;

const routes: Routes = [
	{ path: '', component: WelcomeComponent, canActivate: [UnAuthGuard] },
	{ path: 'home', component: HomeComponent },
	{
		path: '',
		loadChildren: () =>
			import('./pages/account/account.module').then(
				(m) => m.AccountModule
			),
	},
	{
		path: '',
		loadChildren: () =>
			import('./pages/gift-cards/gift-cards.module').then(
				(m) => m.GiftCardsModule
			),
	},
	{
		path: '',
		loadChildren: () =>
			import('./pages/rewards/rewards.module').then(
				(m) => m.RewardsModule
			),
	},
	{
		path: '',
		loadChildren: () =>
			import('./pages/checkout/checkout.module').then(
				(m) => m.CheckoutModule
			),
	},
	{
		path: '',
		loadChildren: () =>
			import('./pages/menu/menu.module').then((m) => m.MenuModule),
	},
	{
		path: '',
		loadChildren: () =>
			import('./pages/order/order.module').then((m) => m.OrderModule),
	},
	{ path: 'track/:id', component: TrackComponent },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			useHash: window.cordova ? true : false,
			anchorScrolling: 'enabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
