import {BaseStore} from "./base-store.service";
import {Injectable} from "@angular/core";

@Injectable({
	providedIn: 'root'
})
export class GiftPromtStore extends BaseStore {

	private card;
	private date;

	getCard() {
		return this.card;
	}

	constructor() {
		super();
	}

	private setCard(val) {
		this.card = val;
	}

	private updateGiftCards(){
		this.date = new Date();
	}

	show(val) {
		this.commit('setCard', val);
	}
}
