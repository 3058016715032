import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
import { Store } from '../store/store.service';

declare var window;

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  constructor(private http: HttpService, private store: Store) { }

  initNotifications() {
	window.PushNotification.createChannel(
		() => {
		},
		() => {
		},
		{
		  id: 'PushPluginChannel',
		  description: 'Change Default Channel',
		  importance: 5,
		  vibration: true,
		  visibility: 1,
		}
	);

	var registerationInit = window.PushNotification.init({
		android: {
			sound: true,
			vibrate: true,
			forceShow: false,
		},
		ios: {
		  alert: true,
		  badge: true,
		  sound: true,
		},
	});

	var userId = this.store.auth.getUser().id;
	var platformId = window.cordova?.platformId;

	registerationInit.on('registration', data => {
		this.http
		.post(`/auth/users/${userId}/push_tokens`, { 'token': data.registrationId, 'platform': platformId }, { observe: 'response' })
		.subscribe(
			(res) => {
				if (res.status === 200) {
				}
			},
			(res) => {
				if (res.error) {
				}
			}
		);
  
		})

	registerationInit.on('notification', data => { window.navigator.notification.alert(data.message, data.title)})

  }
}
