import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-dummy-placeholder',
	templateUrl: './dummy-placeholder.component.html'
})
export class DummyPlaceholderComponent implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}
}
