import {Component, Input, OnInit} from '@angular/core';
import {Store} from "../../store/store.service";

@Component({
	selector: 'app-profile-fields',
	templateUrl: './profile-fields.component.html'
})
export class ProfileFieldsComponent implements OnInit {

	public settings: any = this.store.settings.getTopBarSettings();

	@Input() fields:any;
	@Input() form:any;
	@Input() appearance:any;
	@Input() labelCategory:string = "account"

	hints = {
		birthday: this.store.translate.instant('hints.birthday'),
		password: this.store.translate.instant('hints.password'),
		smsOptIn: this.store.translate.instant('hints.smsOptIn'),
		phone_country_code: this.store.translate.instant('hints.phone_country_code'),
		email: this.store.translate.instant('hints.email'),
	};
	hidePassword: boolean = false;

	constructor(private store: Store) {
	}

	ngOnInit(): void {}


	unsorted() {
	}

	generatePlaceholder(f) {
		//return this.store.translate.instant('account.' + f.key) + (!f.value.required ? ' ' + this.store.translate.instant(('account.optional_field')) : '')
		return this.store.translate.instant( this.labelCategory + '.' + f.key) + (!f.value.required ? '' : ' *')
	}

	locationsChanged(servers: {}) {
		this.fields['servers'].options = servers
	}

}
