import {Observable, Subject} from "rxjs";

export abstract class BaseStore {

	private _change = new Subject();

	commit(name: string, data?: any) {
		this[name](data);
		this._change.next({name, data})
	}

	getStream$(): Observable<any> {
		return this._change.asObservable();
	}
}
