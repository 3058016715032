import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js/min';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(phoneValue: string): unknown {
	  try {
		  const phoneNumber = parsePhoneNumber(phoneValue);
		  return phoneNumber.formatNational();
	  } catch (error) {
		  return phoneValue;
	  }
  }

}
