import {Injectable, Injector} from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from "rxjs/operators";
import {Store} from "../store/store.service";


@Injectable()
export class ApiBaseInterceptor implements HttpInterceptor {

	store: Store;

	constructor( private inj: Injector) {
		setTimeout(()=>{
			this.store = this.inj.get(Store);
		})
	}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

		if (this.store){
			!request.params.get('skipLoader') && this.store.settings.commit("setPageLoader", 1);
			return next.handle(request)
				.pipe(
					catchError((err: any) => {
						!request.params.get('skipLoader') && this.store.settings.commit("setPageLoader", -1);
						if (err instanceof HttpErrorResponse) {
							if (err.status === 401) {
								//TODO: add 401 error Logic
								this.store.auth.signOut();
								this.store.settings.flashWarning(this.store.translate.instant("errors.401"));
							}
							throw err;
						}
						return new Observable<HttpEvent<any>>();
					}),
					map(event=>{
						if (event instanceof HttpResponse ) {
							!request.params.get('skipLoader') && this.store.settings.commit("setPageLoader", -1);
						}
						return event
					})
				);
		}else{
			return next.handle(request)
		}
	}
}
