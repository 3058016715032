import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopBarComponent } from './shared/top-bar/top-bar.component';
import {
	HTTP_INTERCEPTORS,
	HttpClient,
	HttpClientModule,
} from '@angular/common/http';
import { ApiBaseInterceptor } from './services/api-base.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { AlertComponent } from './shared/alert/alert.component';
import { PromtComponent } from './shared/promt/promt.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { SharedModule } from './shared/shared.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HomeComponent } from './pages/home/home.component';
import { SpecialsComponent } from './pages/home/specials/specials.component';
import { LoyaltyProfileComponent } from './pages/home/loyalty-profile/loyalty-profile.component';
import { OffersListComponent } from './pages/home/offers-list/offers-list.component';
import { FavouriteLocationComponent } from './pages/home/favourite-location/favourite-location.component';
import { PreviousOrderComponent } from './pages/home/previous-order/previous-order.component';
import { GoogleMapsConfigInterceptor } from './services/google-maps-config.interceptor';
import { Store } from './store/store.service';
import { LocalStorageService } from './services/local-storage.service';
import { DummyPlaceholderComponent } from './pages/home/dummy-placeholder/dummy-placeholder.component';
import { TrackComponent } from './pages/track/track.component';
import { ActiveOrderComponent } from './pages/home/active-order/active-order.component';

declare var window;

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initializeApp(store: Store, storage: LocalStorageService) {
	return (): Promise<any> => {
		return new Promise((resolve) => {
			let newGroupId = store.settings.getParameterByName('groupId');
			newGroupId && this.storage.setItem('groupId', newGroupId);

			let subdomain = window.location.hostname.split('.')[0];

			store.settings.commit(
				'setGroupId',
				storage.getItem('groupId') ||
					(window.cordova || window.testMode
						? window.asoc.groupId
						: subdomain)
			);

			//Load saved user from localstorage
			store.auth.loadSavedUser();

			//Load App Settings
			store.settings.loadSettings((data) => {
				store.cart.checkSaved(() => {
					resolve(true);
				});
				store.auth.loadLocale();
			});

			//Scroll to error input
			store.settings.initScrollErrorForm();
		});
	};
}

@NgModule({
	declarations: [
		AppComponent,
		TopBarComponent,
		WelcomeComponent,
		HomeComponent,
		AlertComponent,
		PromtComponent,
		LoaderComponent,
		SpecialsComponent,
		LoyaltyProfileComponent,
		OffersListComponent,
		FavouriteLocationComponent,
		PreviousOrderComponent,
		DummyPlaceholderComponent,
		TrackComponent,
		ActiveOrderComponent
	],
	imports: [
		SharedModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		AgmCoreModule.forRoot({
			apiKey: '',
			libraries: ['places'],
		}),
		TranslateModule.forRoot({
			defaultLanguage: 'en',
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ApiBaseInterceptor,
			multi: true,
		},
		{
			provide: LAZY_MAPS_API_CONFIG,
			useClass: GoogleMapsConfigInterceptor,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			multi: true,
			deps: [Store, LocalStorageService],
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
