import { Component, Input, OnInit } from '@angular/core';
import { Store } from 'src/app/store/store.service';

@Component({
	selector: 'app-gift-card-auto-recharge-form',
	templateUrl: './gift-card-auto-recharge-form.component.html',
})
export class GiftCardAutoRechargeFormComponent implements OnInit {
	@Input() gcard;

	constructor(public store: Store) {}

	ngOnInit(): void {
		this.store.settings.getStream$().subscribe((data) => {
			if (
				this.gcard.auto_recharge_amount &&
				this.gcard.auto_recharge_threshold
			) {
				this.gcard.auto_recharge_amount =
					+this.gcard.auto_recharge_amount;
				this.gcard.auto_recharge_threshold =
					+this.gcard.auto_recharge_threshold;
				this.gcard.auto_recharge_amount =
					this.gcard.auto_recharge_amount.toFixed(2);
				this.gcard.auto_recharge_threshold =
					this.gcard.auto_recharge_threshold.toFixed(2);
			}
		});
	}

	onFocus(e) {
		if (e.currentTarget.value === 0) {
			e.currentTarget.value = 0;
		}
	}

	changeAmmount(e, key) {
		const el = e.currentTarget;
		if (el.value) {
			this.gcard[key] = el.value;
			this.gcard[key] = this.gcard[key] ? this.gcard[key] : 0;
		}
		this.gcard[key] = this.gcard[key] ? this.gcard[key] : 0;
	}

	customTipDeselect(e, key) {
		const el = e.currentTarget;
		if (el.value) {
			el.value = parseFloat(el.value).toFixed(2);
			this.gcard[key] = parseFloat(el.value).toFixed(2);
		}
	}
}
