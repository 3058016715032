<div class="paymentSection">
	<h3 class="mb-3">{{ title | translate }}</h3>
	<div class="cardList mb-2" [class]="{ asSlider: asSlider }" *ngIf="cards">
		<div
			class="card captText"
			*ngFor="let card of cards; let i = index"
			[class]="{
				active:
					card.credit_card_id === cart.card?.credit_card_id ||
					noActive
			}"
			(click)="selectCard(card)"
		>
			<div
				*ngIf="user"
				class="closeIcon svgIcons close_rounded"
				(click)="removeCard($event, i)"
			></div>
			<div class="cartType">
				<i
					[class]="
						cardBrandClass[card.card_type?.toLowerCase()] ||
						cardBrandClass['unknown']
					"
				></i>
			</div>
			<div class="cartNumber h5" *ngIf="card.credit_card_number">
				{{ "payment.card_ending" | translate }}
				{{ card.credit_card_number.slice(-4) }}
			</div>
			<div class="cartNameExpBox h5">
				<div class="name" *ngIf="card.name_on_card">
					{{ card.name_on_card }}
				</div>
				<div class="date" *ngIf="card.expiration_date">
					{{
						card.expiration_date.indexOf("/") == -1
							? card.expiration_date?.substring(0, 2) +
							  "/" +
							  card.expiration_date?.substring(2, 4)
							: card.expiration_date?.substring(
									0,
									card.expiration_date?.indexOf("/")
							  ) +
							  "/" +
							  card.expiration_date?.substring(
									card.expiration_date?.indexOf("/") + 1
							  )
					}}
				</div>
			</div>
		</div>
		<div class="pl-3"></div>
	</div>
	<a
		[routerLink]="['/add-card', category, entityID]"
		[queryParams]="{ lastPage: router.url }"
		*ngIf="!hideNavs"
		class="regText mb-2 d-flex align-items-center"
	>
		<ng-container *ngIf="!paymentTypes.length">
			<div class="svgIcons rounded_plus mr-2"></div>
			<div>{{ "payment.add_new_card_link" | translate }}</div>
		</ng-container>
	</a>

	<ng-container *ngIf="!!paymentTypes.length">
		<mat-grid-list cols="2" rowHeight="2:1" [gutterSize]="'10px'">
			<mat-grid-tile *ngFor="let item of paymentTypes; let i = index">
				<button
					[queryParams]="{
						lastPage: router.url,
						paramType: item.pay_method
					}"
					[routerLink]="['/add-card', category, entityID]"
					(click)="addCard()"
					mat-button
					color="primary"
					class="my-grid-tile"
				>
					{{ item.title }}
				</button>
			</mat-grid-tile>
		</mat-grid-list>
	</ng-container>
</div>
